import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/builds/mall/duubee-mall-admin/node_modules/core-js/modules/es6.array.iterator.js";
import "/builds/mall/duubee-mall-admin/node_modules/core-js/modules/es6.promise.js";
import "/builds/mall/duubee-mall-admin/node_modules/core-js/modules/es6.object.assign.js";
import "/builds/mall/duubee-mall-admin/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import _i18n from '@/locales';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale'; // lang i18n
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css
import '@/assets/element-#20A9B8/index.css';
import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon
import "./permission"; // permission control

import * as filters from "./filters"; // global filters

import permission from '@/directive/permission/index.js'; // 权限判断指令
import echarts from 'echarts';
import Print from '@/utils/print'; // 打印

Vue.use(Print);
Vue.use(ElementUI, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
});
// locales
locale.i18n(function (key, value) {
  return _i18n.t(key, value);
});
Vue.use(ElementUI, {
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.directive('permission', permission);

// register global utility filters.
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});