export default {
  star: 'Gustar',
  name: 'Nombre',
  id: 'Id',
  address: 'Dirección',
  addressId: 'Dirección  Id',
  actions: 'Operación',
  standard: 'Normas',
  specifications: 'Especificaciones',
  add: 'Adición',
  all: 'todos',
  objectKey: 'Tecla de objeto',
  objectName: 'Nombre del objeto',
  objectSize: 'Tamaño del objeto',
  objectInfo: 'Información del objeto',
  days: 'Días',
  minutes: 'Minutos',
  userOrderPayTimeout: 'Pedido de usuario',
  orderTotal: 'Ordenar',
  orderInfo: 'Información del pedido',
  share: 'Compartir',
  configuration: 'Configuración',
  mallName: 'Nombre del Centro',
  orderConfirmReceiveTimeout: 'Tiempo de recepción confirmado',
  orderCommentTimeout: 'Nota de pedido',
  orderAutoCancel: 'Cancelación automática del pedido',
  orderAutoConfirmReceive: 'Aceptación automática del pedido',
  orderAutoDisableComment: 'Desactivar automáticamente la nota',
  orderAutoDisableReturn: 'Desactivar automáticamente el pedido',
  orderReturnTimeout: 'Pedido de retorno',
  goodsTotal: 'Carga',
  productTotal: 'productos',
  userTotal: 'Usuarios',
  requestNotSupportYet: 'Solicitud no atendida.',
  loginRequired: 'Iniciar sesión, por favor',
  noPermissionsAlert: 'Sin autorización.',
  requestTimeout: 'Tiempo solicitado',
  results: 'Resultados',
  deleted: 'Eliminar',
  number: 'Cantidad',
  picture: 'Imagen',
  pictures: 'Imagens',
  content: 'Contenido',
  goodsReviews: 'Examen del cargamento',
  pictureUrl: 'Imagen URL',
  objectUrl: 'Enlace de Objeto',
  regionName: 'Nombre del área',
  regionCode: 'Código de área',
  regionType: 'Tipo de área',
  type: 'Tipo',
  freeze: 'freeze',
  ipAddress: 'Dirección IP',
  adminUser: 'Administrador usuario',
  uploadObject: 'Cargar objeto',
  uploadErrorHint: 'Por favor, intente más tarde.',
  browserUnSupportFullScreen: 'Pantalla completa, no su',
  screenLayoutSizeChangeSuccess: 'Modificación del tamaño de la pantalla',
  adminUsers: 'Administrar usuario',
  searchHint: 'Búsqueda',
  roleNameHint: 'Nombre del personaje por favor',
  roleName: 'Nombre del personaje',
  description: 'Nota',
  grant: 'Autorizado',
  permissions: 'Competencia',
  roleNameEmptyHint: 'El nombre del personaje no puede estar vacío',
  update: 'Actualización',
  coupon: 'Cupón',
  coupons: 'Cupóns',
  default: 'Incumplimiento',
  yes: 'Si',
  no: 'No',
  province: 'Provincia',
  refresh: 'Actualizar',
  close: 'Cerrar',
  closeOthers: 'Cerrar otros',
  closeAll: 'Cerrar todos',
  layoutSize: 'Tamaño del diseño',
  fullScreen: 'Pantalla',
  changePassword: 'Cambiar contraseña',
  city: 'Ciudad ',
  area: 'Región',
  goodsId: 'Carga Id',
  goodsIdHint: 'Entrada ID por favor',
  newProducts: 'Nuevo productos',
  more: 'Mas',
  brands: 'Marca',
  trending: 'Caliente Ventas ',
  topics: 'Tema',
  home: 'Casa',
  gallery: 'Galería',
  unit: 'Unidad',
  sharePicture: 'Compartir imagen',
  counterPrice: 'Contraoferta',
  isNew: 'Nuevo',
  notNew: 'No es nuevo',
  categories: 'Categoría',
  cart: 'Carretilla',
  my: 'Mía',
  serviceCenter: 'Centro de servicios',
  feedback: 'Respuesta',
  faq: 'FAQ',
  cancel: 'Cancelar',
  orders: 'Ordenars',
  order: 'Ordenar',
  orderSn: 'Ordenar sn',
  orderStatus: 'Estado del pedido',
  orderPrice: 'Precio de compra',
  actualPrice: 'Precio real',
  payTime: 'Tiempo de pago',
  shipSn: 'Enviar sn',
  shipChannel: 'Canal de transmisión',
  ship: 'Barco',
  userInfo: 'Información del usuario',
  orderBy: 'Encargado m.',
  orderDetail: 'Detalles del pedido',
  orderNumber: 'Número de pedido',
  orderCancelled: 'Cancelar el pedido',
  favorites: 'Carpeta',
  shipping: 'Transporte',
  emailNotification: 'Email address of the notification',
  shippingAddress: 'Dirección de entrega',
  selectAll: 'Selección todos',
  amount: 'Cantidad',
  checkout: 'Verificación',
  delete: 'Eliminar',
  edit: 'Edición',
  complete: 'Completar',
  addTime: 'Añadir tiempo',
  favoriteId: 'Los favoritos Id',
  feedbackId: 'Respuesta Id',
  feedbackType: 'Tipo de respuesta',
  feedbackContent: 'Información recibida',
  feedbackPicture: 'Retroalimentación',
  feedbackIdHint: 'Introducir retroalimentación ID por favor',
  adminUsernameHint: 'Nombre de usuario del Administrador',
  adminUserId: 'Administrador usuario Id',
  adminRole: 'Función de administrador ',
  userFeedback: 'Comentarios del usuario ',
  selectHint: 'Seleccionar pista por favor',
  nameInfo: 'Name',
  fieldEmptyHint: 'El campo no puede estar vacío',
  crete: 'Crear',
  userFavorites: 'Carpeta de usuario',
  history: 'historicos',
  clearHistory: 'Borrar historicos',
  openOrders: 'Pedidos pendientes',
  inTransit: 'En camino',
  processing: 'Procesamiento',
  pending: 'Pendiente',
  pendingPayment: 'Cuentas por pagar',
  new: 'Nuevo',
  used: 'Uso',
  expired: 'Expiración',
  expireTime: 'Fecha de expiración',
  contactUs: 'Contacto',
  empty: 'Vacío',
  select: 'Elección',
  spec: 'spec',
  specs: 'Specs',
  error: 'Error',
  specName: 'Spec Nombre',
  userIdHint: 'Introducir usuario Id por favor',
  keyword: 'Clave',
  keywords: 'Palabras clave',
  keywordHint: 'Palabras clave por favor',
  url: 'Url',
  urlHint: 'Entrada url por favor',
  orderSnHint: 'Entrada Número de orden por favor ',
  orderStatusHint: 'Seleccionar Estado del pedido por favor',
  hot: 'Recomendaciones',
  isHot: 'Recomendaciones',
  notHot: 'Normal',
  notDefault: 'No incumplimiento',
  isOnSale: '¿Vender? ',
  onSale: 'Ventas',
  notOnSale: 'no Ventas',
  isDefault: 'Incumplimiento',
  normal: 'Normal',
  setting: 'Configuración',
  goodsDescriptions: 'Descripción del cargamento',
  specSetting: 'Spec Configuración',
  level: 'Horizontal',
  level1: 'Horizontal 1',
  level2: 'Horizontal 2',
  categoryPicture: 'Categoría de imagen',
  issue: 'Emisión',
  issueHint: 'Formular una pregunta por favor',
  contentHint: 'Introducir contenido por favor',
  answer: 'Respuesta',
  parentLevel: 'Nivel de padre',
  searchId: 'Búsqueda Id',
  print: 'Imprimir',
  footprintId: 'Huella Id',
  userSearchHistory: 'Historial de búsquedas',
  searchHistoryKeyword: 'Búsqueda clave histórica',
  propName: 'Nombre de propiedad',
  propValue: 'Valor del activo',
  goodsProps: 'Propiedades del cargamento',
  goodsSpec: 'Carga specs',
  discountedPrice: 'Discounted price',
  specValue: 'Spec Valor',
  specPicture: 'Spec Foto ',
  singleSpec: 'Soltero spec',
  multipleSpecs: 'Peso specs',
  productStock: 'Existencias de productos',
  products: 'Productos',
  sku: 'SKU',
  skuSpecs: 'SKU Specs',
  purchasePrice: 'Purchase Price',
  afterSalesCost: 'After-sales Cost',
  logisticsCost: 'Logistics Cost',
  taxes: 'Taxes',
  provincialAgency: 'Provincial Agency Commission',
  cityAgencyCommission: 'City Agency Commission',
  marketPrice: 'City agency price',
  provincialPrice: 'Provincial agency price',
  provinciaMarket: 'Provincial agent price = store price - City agent price - provincial agent price',
  marketProvincia: 'Provincial agent price = store price - City agent price',
  storePrice: 'Store Price',
  retailPrice: 'Retail Price',
  goodsPrice: 'goods Price',
  memberPrice: 'Member Price',
  suggestedRetailPrice: 'Suggested Retail Price',
  provincialNumber: 'Prov.min.purchase',
  cityAgencyNumber: 'Mun.min.purchase',
  retailNumber: 'Store.min.purchase',
  success: 'éxito',
  stock: 'Acciones',
  properties: 'Atributo',
  details: 'Detalles',
  noDetails: 'No Detalles ',
  language: 'Idiomas',
  onlySupportTwoSpecsForNow: 'Por ahora, sólo hay dos especificaciones.',
  search: 'Búsqueda',
  searchName: 'Serĉnomo',
  views: 'Observaciones',
  generateQrCode: 'QR code',
  price: 'Precio',
  editGoods: 'Editar producto',
  goodsReservePrice: 'Reserva Precio',
  goodsName: 'Nombre del cargamento',
  goodsBrief: 'Descripción del cargamento',
  goodsDetail: 'Carga Detalles',
  goodsSn: 'Carga Número de serie',
  goodsSnHint: 'Carga Número de serie por favor',
  goodsNameHint: 'Nombre del cargamento por favor',
  addGoodsSpec: 'Adición  Carga spec',
  reviewContent: 'Contenido del examen',
  customers: 'Cliente',
  title: 'Título',
  subtitle: 'Subtítulo',
  titleHint: 'Introducir título por favor',
  subtitleHint: 'Introducir subtítulos por favor',
  adTitleHint: 'Entrada de título por favor',
  view: 'Ver',
  backToTop: 'Volver Hasta Vértice ',
  adId: 'Ad Id',
  adTitle: 'Ad Título ',
  adContent: 'Ad Contenido',
  position: 'Puestos',
  activeLink: 'Enlace activo',
  enable: 'Activar',
  disable: 'Discapacitados',
  adPosition: 'Ad Ubicación',
  adContentHint: 'Introducir Ad contenido por favor',
  homePage: 'Casa Página',
  pictureUploadHint: 'Cargar archivo de  uno jpg / png, No más 1024kb',
  orderTime: 'Tiempo de pedido',
  cancelOrder: 'Cancelar el pedido',
  deleteOrder: 'Eliminar orden',
  pay: 'Pagar',
  receipt: 'Recibo',
  refund: 'Reintegro',
  expressCompany: 'Empresas de mensajería',
  trackingNumber: 'Número de rastreo',
  orderDeleted: 'Pedido borrado.',
  review: 'Recordar',
  logout: 'Paso',
  avatar: 'Personificación',
  male: 'Hombres',
  female: 'Mujeres',
  secrecy: 'Confidencialidad',
  mobile: 'Teléfono',
  gender: 'Género',
  password: 'Contraseña',
  refundCancelled: 'Se canceló.',
  refundBeingProcessed: 'Se están tramitando reembolsos.',
  refundCompleted: 'Reembolso completo.',
  contactNumber: 'Número de Contacto ',
  updateSuccess: 'Actualizar con éxito.',
  confirm: 'Confirmar',
  coding: 'Kodado',
  searchCoding: 'Serĉa kodado',
  roleType: 'Tipo de función',
  userLevel: 'Horizontal',
  silver: 'Plata',
  golden: 'Rubio',
  provincialAgents: 'Provincial Agents',
  cityAgent: 'City Agent',
  storefront: 'Storefront',
  diamond: 'Diamantes',
  member: 'Miembros',
  dealer: 'Distribuidor ',
  oldPassword: 'Contraseña antigua',
  newPassword: 'Nueva contraseña',
  confirmNewPassword: 'Confirmen la nueva contraseña',
  passwordNotMatch: 'Contraseña no coincidente',
  passwordEmptyHint: 'La contraseña no está vacía.',
  userIssue: 'Problema del usuario',
  brand: 'Marca',
  brandIdHint: ' Introducir marca id por favor',
  brandNameHint: 'Entrada Nombre de marca por favor',
  brandId: 'Marca Id',
  brandName: 'Nombre de marca',
  category: 'Categoría',
  categoryId: 'Categoría Id',
  categoryName: 'Nombre de categoría',
  categoryIcon: 'Icono de categoría',
  birthday: 'Cumpleaños',
  phoneNumber: 'Número de teléfono',
  addressDetail: 'Dirección detallada',
  userId: 'Usuario ID',
  receiver: 'Receptor',
  receiverHint: 'Nombre del destinatario ',
  searchingHint: 'Búsqueda ...',
  export: 'Exportaciones ',
  nickname: 'Apodo',
  username: 'Nombre de usuario',
  usernameHint: 'Nombre de usuario ',
  receiveUsername: 'Nombre de usuario',
  phoneNumberHint: 'Número de teléfono',
  status: 'Estatuto',
  unregister: 'No registrado ',
  invalid: 'No válido',
  active: 'Activo',
  memberList: 'Lista de miembros',
  create: 'Crear',
  dealerProfit: 'Beneficios',
  profitRate: 'Margen de beneficio',
  deleteAlert: 'Esto eliminará permanentemente el archivo¿Continuar? ',
  ok: 'OK',
  warning: 'Advertencia',
  deleteSuccess: 'Borrar éxito',
  loginHead: 'Administrador del centro comercial Mendoza',
  login: 'Iniciar sesión ',
  passwordEmpty: 'Contraseña vacía',
  passwordLengthInvalid: 'Longitud de la contraseña',
  accountEmpty: 'Cuenta vacía',
  normalAction: 'Normal',
  safeAction: 'Seguridad ',
  orderAction: 'Ordenar',
  otherAction: 'Otros',
  paymentInfo: 'Información de pago',
  orderRemark: 'Orden de compra',
  receiverType: 'Tipo de receptor',
  messageType: 'Tipo',
  messageContent: 'Contenido',
  messageSubject: 'Tema',
  messageTypeEmptyHint: 'Seleccionar tipo de mensaje por favor',
  receiverTypeEmptyHint: 'Seleccionar tipo de receptor por favor',
  messageContentEmptyHint: 'Introducir contenido de mensaje por favor',
  messageSubjectEmptyHint: 'Introducir tema de mensaje por favor',
  revoke: 'Anulación',
  publish: 'Publicación',
  loadingHint: 'Cargar ...',
  objectId: 'Objeto Id',
  limitType: 'Tipo límite',
  purchaseMin: 'Compra mínima',
  purchaseMax: 'Límite de compra',
  showTieredPrice: 'Show Tiered Price',
  subscribeCheck: 'Necesito subscribir micro carta pública',
  minimumSpendingRequiredForFreeShipping: 'Costo mínimo de la entrega gratuita',
  shippingCostsHint: 'Transporte Costo ',
  refundAmount: 'Cantidad reembolsada ',
  account: 'Cuenta',
  emailAddress: 'Dirección de Email',
  defaultSelect: 'Selección predeterminada',
  onlySupportOneDefaultSelect: 'solo one spec se puede configurar como valor predeterminado ',
  applicationType: 'Tipo de aplicación ',
  expiredTime: 'Tiempo de Expiración ',
  discountRate: 'Tasa de descuento',
  applicationQuantity: 'Medida de Aplicación',
  activationCode: 'Coupon Code',
  masterName: 'Superior Agent',
  inventoryQuantity: 'Inventory quantity',
  applicationReason: 'Motivo de Aplicación',
  applicant: 'Solicitantes',
  count: 'Real-time inventory',
  submit: 'Presentación',
  createApplication: 'Crear aplicación',
  submitApplication: 'Presentación de Aplicación',
  saveDraft: 'Guardar Borrador',
  approve: 'Ratificación',
  reject: 'Rechazar',
  approvalComments: 'Ratificación Observaciones ',
  resubmit: 'Nueva presentación',
  reviewer: 'Auditor Externo',
  applicationForm: 'Formulario de solicitud ',
  applicationCodeList: 'Lista de códigos',
  staffPosition: 'Puestos',
  staffSuperior: 'Superior',
  staffSuperiorId: 'Superior id',
  codeUseTime: 'Tiempo de uso',
  salesCommission: 'Comisión de ventas',
  activeType: 'Tipo de actividad',
  superiorAgent: 'Superior Agent',
  updateDraft: 'Proyecto de actualización ',
  checker: 'Corrector de pruebas',
  checkedTime: 'Tiempo de control',
  listView: 'Vista de lista',
  treeView: 'Diagrama de árbol',
  superiorDealerId: 'Distribuidor principal ID ',
  dealerGrade: 'Dealer Grade',
  superiorDealer: 'Superior Distribuidor',
  lastLoginIp: 'Última entrada ip',
  lastLoginTime: 'Última entrada Tiempo',
  showId: 'Andén id',
  emailActive: 'Email Positivo',
  weChatSubscribe: 'WeChat Subscribir',
  weChatAutoRegister: 'WeChat Registro',
  salesId: 'Ventas id',
  bankNo: 'Número de tarjeta bancaria',
  contactInformation: 'Información de contacto',
  bankAddress: 'Dirección del banco',
  bankType: 'Tipo de banco',
  withdrawalAmount: 'Monto de retiro',
  remittance: 'Remesa',
  confirmApproval: 'Confirmar Aprobación?',
  isReject: 'Rechazar？',
  discountRateMess: 'Ingrese 9 para 10% de descuento, 9.9 para 1%',
  purchaseMaxMess: 'Máx. artículos por código',
  createQuantityMess: 'Cantidad de códigos',
  productViews: 'Vistas de Productos',
  pageViews: 'Vistas de Página',
  pageTraffic: 'Tráfico de Página',
  productFavorites: 'Favoritos de Producto',
  mallPageViews: 'Mall visits',
  unitTime: 'Unit per time',
  month: 'Month',
  pageView: 'Page view',
  favoritesCount: 'Cantidad de Favoritos',
  favoritesVolume: 'Volumen de Favoritos',
  times: 'Veces',
  suggestDivideInto: 'Participación Recomendada',
  suggestPrice: 'Precio Recomendado',
  securityVerification: 'Por favor, complete la verificación de seguridad.',
  loadingCaptcha: 'Cargando código de verificación...',
  verificationFailed: 'Verificación fallida',
  verificationSuccessful: 'Verificación exitosa',
  swipeToVerify: 'Desliza a la derecha para verificar',
  manualEntryCount: 'Manual Inventory Input',
  realTimeCount: 'Entrada Manual de Inventario',
  languagesCode: {
    en: 'English',
    zh: '简体中文',
    es: 'Español'
  },
  tradeStatus: {
    0: 'Pend.',
    1: 'Remit.',
    2: 'Conf.',
    3: 'Hecho',
    4: 'Deneg.'
  },
  withdrawStatusBtn: {
    0: 'Aprobado',
    1: 'Rechazado'
  },
  orderStatusCode: {
    101: 'No Pagar',
    102: 'Cancelar',
    103: 'Tiempo de pago',
    104: 'Sistema cancelado',
    128: 'Comprobante de pago',
    201: 'Pagar',
    202: 'Reintegro',
    203: 'Reintegro Confirmado',
    204: 'Tiempo libre',
    228: 'Recolección Confirmado',
    301: 'Embarque ',
    401: 'Recibo Confirmado',
    402: 'Automaticidad Recibo Confirmado'
  },
  paymentTypesCode: {
    0: 'Desconectar Pago',
    1: 'Alipay',
    2: 'Wechat'
  },
  payType: {
    1: 'Alipay',
    2: 'Wechat'
  },
  cancelReasonCode: {
    1: 'Error en la información del pedido (especificación / tamaño / color/etc).',
    2: 'No quiero',
    3: 'Error en la información (teléfono / dirección)',
    4: 'Entrega retrasada',
    5: 'Agotado'
  },
  receiverTypeCode: {
    0: 'Miembros',
    1: 'Distribuidor',
    99: 'Todos los usuarios'
  },
  messageTypeCode: {
    0: 'Sistema',
    1: 'Ordenar',
    2: 'Promoción',
    3: 'Anuncio'
  },
  giftCodeTypes: {
    1: 'Código de regalo',
    2: 'Código de promoción'
  },
  taskStatus: {
    0: 'Proyecto',
    1: 'Presentación',
    '-1': 'Rechazar',
    2: 'Aprobado',
    3: 'Seleccionado'
  },
  codeStatus: {
    0: 'NA',
    1: 'Validez',
    2: 'Uso',
    3: 'No válido'
  },
  positionCode: {
    1000: 'Gerente de ventas',
    1001: 'Director de ventas',
    1002: 'Ventas'
  },
  dealerLevelCode: {
    1: 'Distribuidor primario',
    2: 'Distribuidor secundario',
    3: 'Distribuidor de nivel 3 '
  },
  userLevelCode: {
    1: 'Provincial Agents',
    2: 'City Agent',
    3: 'Storefront'
  },
  menus: {
    homePage: 'Panel de instrumentos',
    userManage: 'Gestión de usuarios',
    memberManage: 'Gestión de Miembros',
    address: 'Dirección',
    collect: 'Carpeta',
    footprint: 'Huella',
    history: 'historicos',
    feedback: 'Respuesta',
    mallManage: 'Administración del Centro',
    warehouse: 'Depósito',
    region: 'Región',
    brand: 'Marca',
    category: 'Categoría',
    order: 'Ordenar',
    issue: 'Emisión',
    keyword: 'Clave de búsqueda',
    goodsManage: 'Gestión de Carga',
    goods: 'Carga',
    goodsOnSale: 'Ventas',
    goodsEdit: 'Editor de productos',
    goodsComment: 'Observaciones',
    goodsStock: 'Inventario',
    promotionManage: 'Gestión de Promoción ',
    giftCode: 'Código de regalo ',
    adManage: 'Gestión de Ad',
    comments: 'Observaciones',
    topicManage: 'Gestión de Tema',
    topicCreate: 'Creación de tema',
    topicEdit: 'Editar Tema',
    systemManage: 'Gestión del sistema',
    adminManage: 'Gestión administrativa ',
    logs: 'Tronco',
    roleManage: 'Gestión de funciones',
    objectStorage: 'Almacenamiento de objetos',
    configManage: 'Gestión de configuraciones',
    notification: 'Aviso de pedido',
    mallConfig: 'Centro Comercial ',
    expressConfig: 'Expreso',
    orderConfig: 'Ordenar',
    dealerProfit: 'Beneficios del distribuidor',
    statistics: 'Estadística',
    userStatistics: 'Usuario',
    orderStatistics: 'Ordenar',
    goodsStatistics: 'Carga',
    changePassword: 'Cambiar Contraseña ',
    messageCenter: 'Centro de Mensaje ',
    salesManage: 'Gestión de ventas',
    adminMember: 'Gestión de Miembros ',
    commission: 'Comisión de ventas ',
    inviteCode: 'Código de invitación',
    dealer: 'Gestión de Distribuidor',
    salesOrder: 'Pedido del cliente'
  }
};