var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: {
          "toggle-click": _vm.toggleSideBar,
          "is-active": _vm.sidebar.opened,
        },
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("fullScreen"),
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("screenfull", {
                      staticClass: "screenfull right-menu-item",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("layoutSize"),
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("size-select", {
                      staticClass: "international right-menu-item",
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm.avatar
                  ? _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.avatar },
                    })
                  : _c("img", {
                      staticClass: "user-avatar",
                      attrs: {
                        src: require("../../../assets/images/user.png"),
                      },
                    }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.name) +
                          " " +
                          _vm._s(
                            _vm.positions
                              ? "(" +
                                  _vm.$t("positionCode." + _vm.positions) +
                                  ")"
                              : ""
                          )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    { attrs: { divided: "" } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/profile/password" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("changePassword")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    { attrs: { divided: "" } },
                    [
                      _c("language", {
                        attrs: { placement: "left", icon: false },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v(_vm._s(_vm.$t("logout")))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }