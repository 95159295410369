import _objectSpread from "/builds/mall/duubee-mall-admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import SidebarItem from "./SidebarItem";
export default {
  components: {
    SidebarItem: SidebarItem
  },
  data: function data() {
    return {
      menuTitle: process.env.VUE_APP_MENU_TITLE
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['permission_routers', 'sidebar'])), {}, {
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};