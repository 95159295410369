import i18n from '@/locales';
export var statusMap = {
  101: i18n.tc('orderStatusCode.101'),
  102: i18n.tc('orderStatusCode.102'),
  103: i18n.tc('orderStatusCode.103'),
  104: i18n.tc('orderStatusCode.104'),
  128: i18n.tc('orderStatusCode.128'),
  200: i18n.tc('orderStatusCode.200'),
  201: i18n.tc('orderStatusCode.201'),
  202: i18n.tc('orderStatusCode.202'),
  203: i18n.tc('orderStatusCode.203'),
  204: i18n.tc('orderStatusCode.204'),
  301: i18n.tc('orderStatusCode.301'),
  401: i18n.tc('orderStatusCode.401'),
  402: i18n.tc('orderStatusCode.402'),
  502: i18n.tc('orderStatusCode.502')
};
export var paymentAccountTypeOptions = [{
  value: 20,
  label: i18n.tc('paymentAccountTypeCode.20')
}, {
  value: 40,
  label: i18n.tc('paymentAccountTypeCode.40')
}];