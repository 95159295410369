import axios from 'axios';
import i18n from '@/locales';
import { Message, MessageBox } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  timeout: process.env.VUE_APP_REQUEST_TIMEOUT // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  config.url = "/admin".concat(config.url);
  // Do something before request is sent
  if (store.getters.token) {
    // 让每个请求携带token-- ['X-Litemall-Admin-Token']为自定义key 请根据实际情况自行修改
    config.headers['X-Litemall-Admin-Token'] = getToken();
  }
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug
  Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  var res = response.data;
  if (res.errno === 501) {
    MessageBox.alert(i18n.tc('loginRequired'), i18n.tc('error'), {
      confirmButtonText: i18n.tc('confirm'),
      type: 'error'
    }).then(function () {
      store.dispatch('FedLogOut').then(function () {
        location.reload();
      });
    });
    return Promise.reject(response);
  } else if (res.errno === 700 || res.errno === 605) {
    return Promise.reject(response);
  } else if (res.errno !== 0) {
    MessageBox.alert(i18n.tc("errno.".concat(res.errmsg)) ? i18n.tc("errno.".concat(res.errmsg)) : i18n.tc("errno.".concat(res.errno)), i18n.tc('warning'), {
      confirmButtonText: i18n.tc('confirm'),
      type: 'error'
    });
    // 非5xx的错误属于业务错误，留给具体页面处理
    return Promise.reject(response);
  } else {
    return response;
  }
}, function (error) {
  if (error.errno === 501) {
    MessageBox.alert(i18n.tc('loginRequired'), i18n.tc('error'), {
      confirmButtonText: i18n.tc('confirm'),
      type: 'error'
    }).then(function () {
      store.dispatch('FedLogOut').then(function () {
        location.reload();
      });
    });
  } else {
    if (error.errno !== 700 || error.errno !== 605) {
      Message({
        message: i18n.tc('requestTimeout'),
        type: 'error',
        duration: 5 * 1000
      });
    }
  }
  return Promise.reject(error);
});
export default service;