import request from '@/utils/request';
export function loginByUsername(username, password, verificationCode) {
  var data = {
    username: username,
    password: password,
    verificationCode: verificationCode
  };
  return request({
    url: '/auth/login',
    method: 'post',
    data: data
  });
}

// 获取验证码
export function getVerificationCode(username) {
  var data = {
    username: username
  };
  return request({
    url: '/auth/verification',
    method: 'post',
    data: data
  });
}
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post'
  });
}
export function getUserInfo(token) {
  return request({
    url: '/auth/info',
    method: 'get',
    params: {
      token: token
    }
  });
}

// 获取验证图片  以及token
export function reqGet(data) {
  return request({
    url: '/auth/captcha/get',
    method: 'post',
    data: data
  });
}

// 滑动或者点选验证
export function reqCheck(data) {
  return request({
    url: '/auth/captcha/check',
    method: 'post',
    data: data
  });
}