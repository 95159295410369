var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      staticClass: "international",
      attrs: {
        placement: _vm.placement,
        trigger: _vm.trigger,
        "show-timeout": 0,
      },
      on: { command: _vm.handleSetLanguage },
    },
    [
      _c(
        "div",
        [
          _vm.icon
            ? _c("svg-icon", { attrs: { "icon-class": "international" } })
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.$t("language")) + "\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.languagesCode, function (code) {
          return _c(
            "el-dropdown-item",
            { key: code, attrs: { command: code } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("languagesCode." + code)) + "\n    "
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }