import Vue from 'vue';
import Vuex from 'vuex';
import app from "./modules/app";
import permission from "./modules/permission";
import tagsView from "./modules/tagsView";
import user from "./modules/user";
import getters from "./getters";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    permission: permission,
    tagsView: tagsView,
    user: user
  },
  getters: getters
});
export default store;