import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Language',
  props: {
    placement: {
      type: String,
      default: 'top'
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      languagesCode: []
    };
  },
  computed: {
    language: function language() {
      return this.$store.getters.language;
    }
  },
  mounted: function mounted() {
    var _this = this;
    var languages = process.env.VUE_APP_LANGUAGES.split(',');
    languages.forEach(function (code) {
      _this.languagesCode.push(code);
    });
  },
  methods: {
    handleSetLanguage: function handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('setLanguage', lang);
      this.$message({
        message: this.$i18n.tc('success'),
        type: 'success'
      });
      this.$router.go(0);
    }
  }
};