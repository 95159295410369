export function addressProcessor(address) {
  if (!address) return false;
  if (typeof address === 'string') {
    address = JSON.parse(address);
  }
  var addressString = '';
  if (address.province) {
    addressString += address.province + ' ';
  }
  if (address.city) {
    addressString += address.city + ' ';
  }
  if (address.county) {
    addressString += address.county + ' ';
  }
  if (address.addressDetail) {
    addressString += address.addressDetail;
  }
  return addressString;
}
export function getHistoryByStatusCode(historyList, code) {
  if (!historyList) {
    historyList = [];
  }
  return historyList.filter(function (history) {
    return parseInt(history.historyStatus) === code;
  });
}
export function getUserLanguage() {
  return navigator.language || navigator.userLanguage;
}