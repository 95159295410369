export default {
  star: 'Like',
  name: 'Name',
  id: 'Id',
  address: 'Address',
  addressId: 'Address Id',
  actions: 'Actions',
  standard: 'Standard',
  specifications: 'Specifications',
  add: 'Add',
  all: 'All',
  objectKey: 'Object key',
  objectName: 'Object name',
  objectSize: 'Object size',
  objectInfo: 'Object info',
  days: 'Days',
  minutes: 'Minutes',
  userOrderPayTimeout: 'User order pay timeout',
  orderTotal: 'Orders',
  orderInfo: 'Order info',
  share: 'Share',
  configuration: 'Configuration',
  mallName: 'Mall name',
  orderConfirmReceiveTimeout: 'Order confirm receive timeout',
  orderCommentTimeout: 'Order comment timeout',
  orderAutoCancel: 'Order auto cancel',
  orderAutoConfirmReceive: 'Order auto confirm receive',
  orderAutoDisableComment: 'Order auto disable comment',
  orderAutoDisableReturn: 'Order auto disable return',
  orderReturnTimeout: 'Order return timeout',
  goodsTotal: 'Goods',
  productTotal: 'Products',
  userTotal: 'Users',
  loginRequired: 'Please login',
  noPermissionsAlert: 'No permissions',
  requestTimeout: 'Request timeout',
  results: 'Results',
  deleted: 'Deleted',
  number: 'Number',
  picture: 'Picture',
  pictures: 'Pictures',
  content: 'Content',
  goodsReviews: 'Goods reviews',
  pictureUrl: 'Picture url',
  objectUrl: 'Object Link',
  regionName: 'Region name',
  regionCode: 'Region code',
  regionType: 'Region type',
  type: 'Type',
  freeze: 'freeze',
  ipAddress: 'Ip address',
  adminUser: 'Admin user',
  uploadObject: 'Upload object',
  uploadErrorHint: 'Upload failed, please tay again later',
  browserUnSupportFullScreen: 'Browser unsupport full screen',
  screenLayoutSizeChangeSuccess: 'Screen layout size change success',
  adminUsers: 'Admin users',
  searchHint: 'Search',
  roleNameHint: 'Please input role name',
  roleName: 'Role name',
  description: 'Description',
  grant: 'Grant',
  permissions: 'Permissions',
  roleNameEmptyHint: 'Role name can not be empty',
  update: 'Update',
  coupon: 'Coupon',
  coupons: 'Coupons',
  default: 'Default',
  yes: 'Yes',
  no: 'No',
  province: 'Province',
  refresh: 'Refresh',
  close: 'Close',
  closeOthers: 'Close others',
  closeAll: 'Close all',
  layoutSize: 'Layout size',
  fullScreen: 'Full screen',
  changePassword: 'Change password',
  city: 'City',
  area: 'Area',
  goodsId: 'Goods Id',
  goodsIdSelect: 'Please select the product id first',
  goodsIdSelectName: 'Please select the product',
  goodsSeleter: 'Product selector',
  goodsIdHint: 'Please input goods Id',
  newProducts: 'New Products',
  more: 'More',
  emailNotification: 'Email address of the notification',
  brands: 'Brands',
  trending: 'Trending',
  topics: 'Topics',
  home: 'Home',
  gallery: 'Gallery',
  unit: 'Unit',
  nameInfo: 'Name',
  sharePicture: 'Share picture',
  counterPrice: 'Counter price',
  isNew: 'New',
  notNew: 'Not new',
  categories: 'Categories',
  cart: 'Cart',
  my: 'My',
  serviceCenter: 'Service Center',
  feedback: 'Feedback',
  faq: 'FAQ',
  cancel: 'Cancel',
  orders: 'Orders',
  order: 'Order',
  orderSn: 'Order sn',
  orderStatus: 'Order status',
  orderPrice: 'Order price',
  actualPrice: 'Actual price',
  payTime: 'Pay time',
  shipSn: 'Ship sn',
  shipChannel: 'Ship channel',
  ship: 'Ship',
  userInfo: 'User info',
  orderBy: 'Order by',
  orderDetail: 'Order detail',
  orderNumber: 'Order number',
  orderCancelled: 'Order cancelled',
  favorites: 'Favorites',
  shipping: 'Shipping',
  shippingAddress: 'Shipping address',
  selectAll: 'Select All',
  amount: 'Amount',
  checkout: 'Checkout',
  delete: 'Delete',
  edit: 'Edit',
  complete: 'Complete',
  addTime: 'Add time',
  favoriteId: 'Favorite Id',
  feedbackId: 'Feedback Id',
  feedbackType: 'Feedback type',
  feedbackContent: 'FeedbackContent',
  feedbackPicture: 'Feedback picture',
  feedbackIdHint: 'please input feedback Id',
  adminUsernameHint: 'Admin username hint',
  adminUserId: 'Admin user Id',
  adminRole: 'Admin role',
  userFeedback: 'User feedback',
  selectHint: 'Please select hint',
  fieldEmptyHint: 'Field can not be empty',
  crete: 'Create',
  userFavorites: 'User favorites',
  history: 'History',
  clearHistory: 'Clear history',
  openOrders: 'Open orders',
  inTransit: 'In transit',
  processing: 'Processing',
  pending: 'Pending',
  pendingPayment: 'Pending Payment',
  new: 'New',
  used: 'Used',
  expired: 'Expired',
  expireTime: 'Expire time',
  contactUs: 'Contact us',
  empty: 'Empty',
  select: 'Select',
  spec: 'Spec',
  specs: 'Specs',
  error: 'Error',
  specName: 'Spec name',
  userIdHint: 'Please input user Id',
  keyword: 'Keyword',
  spacTpye: 'Specification classification',
  keywords: 'Keywords',
  keywordHint: 'Please input keyword',
  url: 'Url',
  urlHint: 'please input url',
  orderSnHint: 'Please input order sn',
  orderStatusHint: 'Please select order status',
  hot: 'Hot sales',
  isHot: 'Is hot sales',
  notHot: 'Normal',
  notDefault: 'Not default',
  isOnSale: 'Is on sale',
  onSale: 'On sale',
  notOnSale: 'Not on sale',
  isDefault: 'Default',
  normal: 'Normal',
  setting: 'Setting',
  goodsDescriptions: 'Goods descriptions',
  specSetting: 'Spec setting',
  level: 'Level',
  level1: 'Level 1',
  level2: 'Level 2',
  categoryPicture: 'Category picture',
  issue: 'Issue',
  issueHint: 'please input issue',
  contentHint: 'Please input content',
  answer: 'Answer',
  parentLevel: 'Parent level',
  searchId: 'Search Id',
  print: 'Print',
  footprintId: 'Footprint Id',
  userSearchHistory: 'User search history',
  searchHistoryKeyword: 'Search history keyword',
  propName: 'Property name',
  propValue: 'Property value',
  goodsProps: 'Goods property',
  goodsSpec: 'Goods specs',
  discountedPrice: 'Discounted price',
  specValue: 'Spec value',
  specPicture: 'Spec picture',
  singleSpec: 'Single spec',
  multipleSpecs: 'Multiple specs',
  productStock: 'Product stock',
  products: 'Products',
  sku: 'SKU',
  skuSpecs: 'SKU Specs',
  skuSeleter: 'SKU selector',
  skuIdSelect: 'Please select SKU',
  purchasePrice: 'Purchase Price',
  afterSalesCost: 'After-sales Cost',
  logisticsCost: 'Logistics Cost',
  taxes: 'Taxes',
  provincialAgency: 'Provincial Agency Commission',
  cityAgencyCommission: 'City Agency Commission',
  marketPrice: 'City agency price',
  provincialPrice: 'Provincial agency price',
  provinciaMarket: 'Provincial agent price = store price - City agent price - provincial agent price',
  marketProvincia: 'Provincial agent price = store price - City agent price',
  storePrice: 'Store Price',
  retailPrice: 'Retail Price',
  suggestedRetailPrice: 'Suggested Retail Price',
  provincialNumber: 'Prov.min.purchase',
  cityAgencyNumber: 'Mun.min.purchase',
  retailNumber: 'Store.min.purchase',
  success: 'Success',
  stock: 'Stock',
  properties: 'Properties',
  details: 'Details',
  noDetails: 'No Details',
  language: 'Language',
  onlySupportTwoSpecsForNow: 'Only support two specs for now.',
  search: 'Search',
  searchName: 'Search Name',
  views: 'Views',
  generateQrCode: 'QR code',
  price: 'Price',
  editGoods: 'Edit goods',
  goodsReservePrice: 'Reserve price',
  goodsName: 'Goods name',
  goodsBrief: 'Goods brief',
  goodsDetail: 'Goods detail',
  goodsSn: 'Goods code',
  goodsSnHint: 'Please goods code',
  goodsNameHint: 'Please goods name',
  addGoodsSpec: 'Add goods spec',
  reviewContent: 'Review content',
  customers: 'Customers',
  title: 'Title',
  subtitle: 'Subtitle',
  titleHint: 'Please input title',
  subtitleHint: 'Please input subtitle',
  adTitleHint: 'Please input ad title',
  view: 'View',
  backToTop: 'Back to top',
  adId: 'Ad Id',
  adTitle: 'Ad title',
  adContent: 'Ad content',
  position: 'Position',
  activeLink: 'Active link',
  enable: 'Enable',
  disable: 'Disable',
  adPosition: 'Ad position',
  adContentHint: 'Please input ad content',
  homePage: 'Home page',
  pictureUploadHint: 'Upload a jpg / png file, no larger than 1024kb',
  orderTime: 'Order time',
  cancelOrder: 'Cancel order',
  deleteOrder: 'Delete order',
  pay: 'Pay',
  receipt: 'Receipt',
  refund: 'Refund',
  expressCompany: 'Express Company',
  trackingNumber: 'TrackingNumber',
  orderDeleted: 'Order deleted.',
  review: 'Review',
  logout: 'Logout',
  avatar: 'Avatar',
  male: 'Male',
  female: 'Female',
  secrecy: 'Secrecy',
  mobile: 'Mobile',
  gender: 'Gender',
  password: 'Password',
  refundCancelled: 'Refund Cancelled',
  refundBeingProcessed: 'Refund being processed.',
  refundCompleted: 'Refund completed',
  contactNumber: 'Contact Number',
  updateSuccess: 'Update success.',
  confirm: 'Confirm',
  coding: 'code',
  searchCoding: 'Search encoding',
  roleType: 'Role Type',
  userLevel: 'Level',
  silver: 'Silver',
  golden: 'Golden',
  provincialAgents: 'Provincial Agents',
  cityAgent: 'City Agent',
  storefront: 'Storefront',
  diamond: 'Diamond',
  member: 'Member',
  dealer: 'Dealer',
  oldPassword: 'Old password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm new password',
  passwordNotMatch: 'Password not match',
  passwordEmptyHint: 'Password can not be empty',
  userIssue: 'User issues',
  brand: 'Brand',
  brandIdHint: 'Please input brand id',
  brandNameHint: 'Please input brand name',
  brandId: 'Brand Id',
  brandName: 'Brand name',
  category: 'Category',
  categoryId: 'Category Id',
  categoryName: 'Category name',
  categoryIcon: 'Category icon',
  birthday: 'Birthday',
  phoneNumber: 'Phone number',
  addressDetail: 'Address detail',
  userId: 'User ID',
  receiver: 'Receiver',
  receiverHint: 'Receiver name',
  searchingHint: 'Searching...',
  export: 'Export',
  nickname: 'Nickname',
  username: 'Username',
  usernameHint: 'Username',
  receiveUsername: 'Username',
  phoneNumberHint: 'Phone number',
  status: 'Status',
  unregister: 'Unregister',
  invalid: 'Invalid',
  active: 'Active',
  memberList: 'Member List',
  create: 'Create',
  dealerProfit: 'Profit',
  profitRate: 'Profit rate',
  deleteAlert: 'This will permanently delete the file. Continue?',
  ok: 'OK',
  warning: 'Warning',
  deleteSuccess: 'Delete success',
  loginHead: 'Mendoza Mall Admin',
  login: 'Login',
  passwordEmpty: 'Password empty',
  passwordLengthInvalid: 'Password length invalid',
  accountEmpty: 'Account empty',
  normalAction: 'Normal',
  safeAction: 'Safe',
  orderAction: 'Order',
  otherAction: 'Other',
  paymentInfo: 'Payment info',
  orderRemark: 'Order remark',
  receiverType: 'Receiver type',
  messageType: 'Type',
  messageContent: 'content',
  messageSubject: 'Subject',
  messageTypeEmptyHint: 'Please select message type',
  receiverTypeEmptyHint: 'Please select receiver type',
  messageContentEmptyHint: 'Please input message content',
  messageSubjectEmptyHint: 'Please input message subject',
  revoke: 'Revoke',
  publish: 'publish',
  loadingHint: 'Loading...',
  objectId: 'Object Id',
  limitType: 'Limit type',
  purchaseMin: 'Purchase minimum',
  purchaseMax: 'Purchase maximum',
  showTieredPrice: 'Show Tiered Price',
  subscribeCheck: 'Need subscribe WeChat Official Accounts',
  minimumSpendingRequiredForFreeShipping: 'Minimum spending required for free shipping',
  shippingCostsHint: 'Shipping costs',
  refundAmount: 'Refund amount',
  account: 'Account',
  emailAddress: 'Email address',
  defaultSelect: 'Default Select',
  onlySupportOneDefaultSelect: 'Only one spec can be set as default',
  applicationType: 'Application type',
  expiredTime: 'Expired time',
  discountRate: 'Discount rate',
  applicationQuantity: 'Application quantities',
  activationCode: 'Coupon Code',
  applicationReason: 'Application reason',
  applicant: 'Applicant',
  submit: 'Submit',
  createApplication: 'Create application',
  submitApplication: 'Submit application',
  saveDraft: 'Save draft',
  approve: 'Approve',
  reject: 'Reject',
  approvalComments: 'Approval comments',
  resubmit: 'Resubmit',
  approveTime: 'Approve time',
  reviewer: 'First level approver',
  applicationForm: 'Application form',
  applicationCodeList: 'Code list',
  staffPosition: 'Position',
  staffSuperior: 'Superior',
  staffSuperiorId: 'Superior id',
  codeUseTime: 'Used time',
  salesCommission: 'Sales commission',
  activeType: 'Active type',
  superiorAgent: 'Superior Agent',
  masterName: 'Superior Agent',
  updateDraft: 'Update draft',
  checker: 'Second level approver',
  checkedTime: 'approve time',
  listView: 'List view',
  treeView: 'Tree view',
  superiorDealerId: 'Superior dealer id',
  superiorDealerId2: 'Superior dealer Platform id',
  dealerGrade: 'Dealer Grade',
  superiorDealer: 'Superior dealer',
  lastLoginIp: 'Last login ip',
  lastLoginTime: 'Last login time',
  showId: 'Platform id',
  emailActive: 'Email active',
  weChatSubscribe: 'WeChat subscribe',
  weChatAutoRegister: 'WeChat register',
  salesId: 'Sales id',
  myWallet: 'Wallet',
  withdraw: 'Withdraw',
  dealerContactNumber: 'Dealer tel',
  orderId: 'Order id',
  createTime: 'Crete time',
  commissionAmount: 'Total commission',
  withdrawAmount: 'Amount',
  totalCommission: 'Total commission',
  goodsInfo: 'Goods information',
  costInfo: 'Cost information',
  goodsPrice: 'Goods price',
  memberPrice: 'Member Price',
  courierFees: 'Courier fees',
  giftCodeDiscountPrice: ' Gift code discount',
  couponDiscountPrice: 'Coupon discount',
  offPriceDiscount: 'Off price discount',
  integralDiscountPrice: 'Integral discount',
  orderNum: 'Order number',
  accountType: 'Account type',
  accountId: 'Account id',
  withdrawConfirm: 'Withdraw confirm',
  transferConfirm: 'Transfer confirm',
  remark: 'Remark',
  offlineTransferConfirm: 'Offline transfer confirm',
  selectTypeHint: 'Please select type',
  paymentAccountName: 'Account name',
  paymentAccount: 'Account number',
  paymentAccountRequiredHint: 'Payment account required',
  receivingAccount: 'Receiving account',
  dealWith: 'Deal with',
  tradeAccountName: 'Account name',
  tradeAccount: 'Account number',
  WalletBalance: 'Balance',
  singleTime: 'Single time',
  everyTime: 'Every time',
  limitByDays: 'Limit by day(s)',
  bom: 'BOM',
  rules: 'Rules',
  purchaseRules: 'Purchase rules',
  addRules: 'Add rules',
  uploadPictureOverLimitHint: 'Upload picture over limit',
  singleMinimumAmount: 'Single minimum amount',
  dailyLimit: 'Daily limit',
  quantity: 'Quantity',
  count: 'Real-time inventory',
  inventoryQuantity: 'Inventory quantity',
  thumbnail: 'Thumbnail',
  adminPassword: 'Admin password',
  pageNotFound: 'Page not found',
  back: 'Back',
  backToHome: 'Back to home',
  bankNo: 'Bank Card Number',
  contactInformation: 'Contact Information',
  bankAddress: 'Bank Address',
  bankType: 'Bank Type',
  withdrawalAmount: 'Withdrawal Amount',
  notVerificationCode: 'The verification code cannot be empty',
  verificationCode: 'Verification code',
  refreshImg: 'If the picture is not clear, click the picture to refresh',
  enterCode: 'Please enter the verification code based on the above image',
  remittance: 'Remittance',
  confirmApproval: 'Confirm Approval?',
  isReject: 'Reject？',
  superiorAgentRequired: 'Superior Agent is required',
  discountRateMess: 'Enter 9 for 10% off, 9.9 for 1% off',
  purchaseMaxMess: 'Max items per code',
  createQuantityMess: 'Number of codes',
  productViews: 'Product Views',
  pageViews: 'Page Views',
  pageTraffic: 'Page Traffic',
  productFavorites: 'Product Favorites',
  mallPageViews: 'Mall visits',
  unitTime: 'Unit per time',
  month: 'Month',
  pageView: 'Page view',
  favoritesCount: 'Favorites Count',
  favoritesVolume: 'Favorites Volume',
  times: 'Times',
  suggestDivideInto: 'Recommended Share',
  suggestPrice: 'Recommended Price',
  securityVerification: 'Please complete security verification.',
  loadingCaptcha: 'Loading verification code...',
  verificationFailed: 'Verification failed',
  verificationSuccessful: 'Verification successful',
  swipeToVerify: 'Swipe right to verify',
  manualEntryCount: 'Manual Inventory Input',
  realTimeCount: 'Real-Time Inventory',
  withdrawStatusCode: {
    51: 'Pending',
    101: 'Complete'
  },
  paymentAccountTypeCode: {
    20: 'AliPay',
    30: 'WeChat Pay',
    40: 'PayPal'
  },
  tradeTypeCode: {
    10: 'Offline',
    0: 'Online'
  },
  accountTypeCode: {
    1: 'Seller',
    2: 'Dealer'
  },
  languagesCode: {
    en: 'English',
    zh: '简体中文',
    es: 'Español'
  },
  tradeStatus: {
    0: 'Pending',
    1: 'Remit',
    2: 'Confirm',
    3: 'Done',
    4: 'Denied'
  },
  withdrawStatusBtn: {
    0: 'Approved',
    1: 'Rejected'
  },
  orderStatusCode: {
    101: 'Unpaid',
    102: 'Cancel',
    103: 'Payment timeout',
    104: 'System cancel',
    128: 'Payment voucher upload',
    201: 'Paid',
    202: 'Refund',
    203: 'Refund confirm',
    204: 'Group Timeout',
    228: 'Gathering confirm',
    301: 'Shipped',
    401: 'Confirm receipt',
    402: 'Auto confirm receipt',
    502: 'Complete'
  },
  paymentTypesCode: {
    0: 'Offline payment',
    1: 'Alipay',
    2: 'Wechat'
  },
  payType: {
    1: 'Alipay',
    2: 'Wechat',
    3: 'PayPal'
  },
  cancelReasonCode: {
    1: 'Order information shot wrong (specification / size / color, etc.)',
    2: "I don't want anymore",
    3: 'Incorrect address / phone information',
    4: 'Did not ship on time',
    5: 'Out of stock'
  },
  receiverTypeCode: {
    0: 'Member',
    1: 'Dealer',
    99: 'All user'
  },
  messageTypeCode: {
    0: 'System',
    1: 'Order',
    2: 'Promotion',
    3: 'Announcement'
  },
  giftCodeTypes: {
    1: 'Gift code',
    2: 'Promo code'
  },
  taskStatus: {
    0: 'Draft',
    1: 'In Approval',
    '-1': 'Rejected',
    2: 'Approved',
    3: 'Checked'
  },
  codeStatus: {
    0: 'NA',
    1: 'Valid',
    2: 'Used',
    3: 'Invalid'
  },
  positionCode: {
    999: 'National General Manager',
    1000: 'Sales Direct',
    1001: 'Sales Manager',
    1002: 'Sales'
  },
  dealerLevelCode: {
    0: 'Platform',
    1: 'Silver',
    2: 'Golden',
    3: 'Diamond'
  },
  userLevelCode: {
    1: 'Provincial Agents',
    2: 'City Agent',
    3: 'Storefront'
  },
  errno: {
    '-2': 'Payment method is not supported',
    '-1': 'Error',
    100: 'Minimum purchase quantity required',
    101: 'Maximum purchase quantity exceeded',
    102: 'Limit one purchase per user',
    103: 'This product requires a subscription to purchase',
    401: 'Params are incorrect',
    402: 'Parameter value is incorrect',
    501: 'Please login',
    502: 'Internal System Error',
    503: 'Priority purchase products do not support changing the quantity',
    504: 'The updated data is invalid',
    505: 'Failed to update data',
    506: 'No operation authority',
    601: 'Administrator username does not meet the requirements',
    602: 'Administrator password length cannot be less than 6',
    603: 'Administrator already exists',
    604: 'Administrator cannot delete own account',
    605: 'User account or password is incorrect',
    606: 'Email address does not meet regulations',
    611: 'Goods name already exists',
    620: 'The order cannot confirm receipt',
    621: 'Order refund failed',
    622: 'Order items have been replied',
    640: 'Role already exists',
    641: "The current role's super permission cannot be changed",
    642: 'The current role exists as an administrator and cannot be deleted',
    650: 'Group purchase products do not exist',
    651: 'Group purchase products already exist',
    652: 'Group purchase has been offline',
    700: 'Account does not exist or password is incorrect',
    701: 'The applet background verification code service is not supported',
    702: 'The verification code has not timed out for 1 minute and cannot be sent',
    703: 'Verification code error',
    704: 'The username already exists',
    705: 'Phone number is registered',
    706: 'Phone number is not registered',
    707: 'Phone number format is not correct',
    708: 'Openid get failed',
    709: 'Openid has an account bound',
    710: 'This product is no longer available',
    711: 'Inventory shortage',
    712: 'Unknown product',
    720: 'Order does not exist',
    721: "Not the current user's order",
    724: 'The order cannot be paid',
    725: 'Order cannot be operated',
    726: 'Order product has been evaluated',
    727: 'The current product evaluation time has expired',
    1000: 'Withdraw fail',
    4013: 'Verification code error, please re-enter',
    2000: 'Insufficient balance',
    2010: 'Insufficient balance',
    2050: 'Withdrawal service error',
    5000: 'Must meet minimum cash withdrawal amount',
    5001: 'The cash withdrawal amount has reached the upper limit today'
  },
  menus: {
    homePage: 'Dashboard',
    userManage: 'User Management',
    memberManage: 'Member Management',
    address: 'Address',
    collect: 'Favorites',
    footprint: 'Footprint',
    history: 'History',
    feedback: 'Feedback',
    mallManage: 'Mall Management',
    warehouse: 'Warehouse',
    region: 'Region',
    brand: 'Brand',
    category: 'Category',
    order: 'Order',
    issue: 'Issue',
    keyword: 'Search Keyword',
    goodsManage: 'Goods Management',
    goods: 'Goods',
    goodsOnSale: 'On Sale',
    goodsEdit: 'Goods Edit',
    goodsComment: 'Comment',
    goodsStock: 'Stock',
    promotionManage: 'Promotion Management',
    giftCode: 'Gift Code',
    adManage: 'Ad Manage',
    comments: 'Comments',
    topicManage: 'Topic Management',
    topicCreate: 'Topic Create',
    topicEdit: 'Topic Edit',
    systemManage: 'System Management',
    adminManage: 'Admin Management',
    logs: 'Logs',
    roleManage: 'Role Management',
    objectStorage: 'Object Storage',
    configManage: 'Config Management',
    notification: 'Order Notification',
    mallConfig: 'Mall',
    expressConfig: 'Express',
    orderConfig: 'Order',
    dealerProfit: 'Dealer Profit',
    statistics: 'Statistics',
    userStatistics: 'Users',
    orderStatistics: 'Orders',
    goodsStatistics: 'Goods',
    changePassword: 'Change Password',
    messageCenter: 'Message Center',
    salesManage: 'Sales Center',
    adminMember: 'Member Management',
    commission: 'Sales Commission',
    inviteCode: 'Invitation Code',
    dealer: 'Dealer Management',
    salesOrder: 'Customer Order',
    wallet: 'Profit sharing',
    finance: 'Finance Center',
    withdraw: 'Withdraw',
    payment: 'Payment',
    userWallet: 'User Wallet',
    configWithdraw: 'Withdraw Config'
  }
};