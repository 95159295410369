import Vue from 'vue';
import Router from 'vue-router';
import i18n from '@/locales';
Vue.use(Router);

/* Layout */
import Layout from '@/views/layout/Layout';

/** note: Submenu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    perms: ['GET /aaa','POST /bbb']     will control the page perms (you can set multiple perms)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
**/
export var constantRouterMap = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/authredirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/errorPage/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/errorPage/401');
  },
  hidden: true
}, {
  path: '',
  component: Layout,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: i18n.tc('menus.homePage'),
      icon: 'dashboard',
      affix: true
    }
  }]
}];
export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});
export var asyncRouterMap = [{
  path: '/user',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'userManage',
  meta: {
    title: i18n.tc('menus.userManage'),
    icon: 'group'
  },
  children: [{
    path: 'user',
    component: function component() {
      return import('@/views/user/user');
    },
    name: 'user',
    meta: {
      perms: ['GET /admin/user/list'],
      title: i18n.tc('menus.memberManage'),
      noCache: true
    }
  }, {
    path: 'address',
    component: function component() {
      return import('@/views/user/address');
    },
    name: 'address',
    meta: {
      perms: ['GET /admin/address/list'],
      title: i18n.tc('menus.address'),
      noCache: true
    }
  }, {
    path: 'collect',
    component: function component() {
      return import('@/views/user/collect');
    },
    name: 'collect',
    meta: {
      perms: ['GET /admin/collect/list'],
      title: i18n.tc('menus.collect'),
      noCache: true
    }
  }, {
    path: 'footprint',
    component: function component() {
      return import('@/views/user/footprint');
    },
    name: 'footprint',
    meta: {
      perms: ['GET /admin/footprint/list'],
      title: i18n.tc('menus.footprint'),
      noCache: true
    }
  }, {
    path: 'history',
    component: function component() {
      return import('@/views/user/history');
    },
    name: 'history',
    meta: {
      perms: ['GET /admin/history/list'],
      title: i18n.tc('menus.history'),
      noCache: true
    }
  }, {
    path: 'feedback',
    component: function component() {
      return import('@/views/user/feedback');
    },
    name: 'feedback',
    meta: {
      perms: ['GET /admin/feedback/list'],
      title: i18n.tc('menus.feedback'),
      noCache: true
    }
  }]
}, {
  path: '/finance',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'finance',
  meta: {
    title: i18n.tc('menus.finance'),
    // icon: 'investment'
    perms: ['GET /admin/wallet/paymentAccountList', 'GET /admin/wallet/withdrawList', 'GET /admin/wallet/mine'],
    icon: 'coin'
  },
  children: [{
    path: 'wallet',
    component: function component() {
      return import('@/views/finance/wallet');
    },
    name: 'wallet',
    meta: {
      // perms: ['GET /admin/wallet/mine'],
      title: i18n.tc('menus.wallet')
    }
  }, {
    path: 'withdraw',
    component: function component() {
      return import('@/views/finance/withdraw');
    },
    name: 'withdraw',
    meta: {
      // perms: ['GET /admin/wallet/withdrawList'],
      title: i18n.tc('menus.withdraw')
    }
  },
  // 支付方式
  // {
  //   path: 'payment',
  //   component: () => import('@/views/finance/payment'),
  //   name: 'payment',
  //   meta: {
  //     // perms: ['GET /admin/wallet/paymentAccountList'],
  //     title: i18n.tc('menus.payment')
  //   }
  // },
  {
    path: 'user-wallet',
    component: function component() {
      return import('@/views/finance/userWallet');
    },
    name: 'userWallet',
    meta: {
      perms: ['GET /admin/wallet/list'],
      title: i18n.tc('menus.userWallet')
    }
  }]
}, {
  path: '/mall',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'mallManage',
  meta: {
    title: i18n.tc('menus.mallManage'),
    icon: 'mall'
  },
  children: [{
    path: 'warehouse',
    component: function component() {
      return import('@/views/mall/warehouse');
    },
    name: 'Warehouse',
    meta: {
      perms: ['GET /admin/warehouse'],
      title: i18n.tc('menus.warehouse'),
      noCache: true
    }
  },
  // {
  //   path: 'region',
  //   component: () => import('@/views/mall/region'),
  //   name: 'region',
  //   meta: {
  //     title: i18n.tc('menus.region'),
  //     noCache: true
  //   }
  // },
  {
    path: 'brand',
    component: function component() {
      return import('@/views/mall/brand');
    },
    name: 'brand',
    meta: {
      perms: ['GET /admin/brand/list', 'POST /admin/brand/create', 'GET /admin/brand/read', 'POST /admin/brand/update', 'POST /admin/brand/delete'],
      title: i18n.tc('menus.brand'),
      noCache: true
    }
  }, {
    path: 'category',
    component: function component() {
      return import('@/views/mall/category');
    },
    name: 'category',
    meta: {
      perms: ['GET /admin/category/list', 'POST /admin/category/create', 'GET /admin/category/read', 'POST /admin/category/update', 'POST /admin/category/delete'],
      title: i18n.tc('menus.category'),
      noCache: true
    }
  }, {
    path: 'order',
    component: function component() {
      return import('@/views/mall/order');
    },
    name: 'order',
    meta: {
      perms: ['GET /admin/order/list', 'GET /admin/order/detail', 'POST /admin/order/ordership', 'POST /admin/order/orderrefund', 'POST /admin/order/orderreply'],
      title: i18n.tc('menus.order'),
      noCache: true
    }
  }, {
    path: 'issue',
    component: function component() {
      return import('@/views/mall/issue');
    },
    name: 'issue',
    meta: {
      perms: ['GET /admin/issue/list', 'POST /admin/issue/create', 'GET /admin/issue/read', 'POST /admin/issue/update', 'POST /admin/issue/delete'],
      title: i18n.tc('menus.issue'),
      noCache: true
    }
  }, {
    path: 'keyword',
    component: function component() {
      return import('@/views/mall/keyword');
    },
    name: 'keyword',
    meta: {
      perms: ['GET /admin/keyword/list', 'POST /admin/keyword/create', 'GET /admin/keyword/read', 'POST /admin/keyword/update', 'POST /admin/keyword/delete'],
      title: i18n.tc('menus.keyword'),
      noCache: true
    }
  }, {
    path: 'message',
    component: function component() {
      return import('@/views/mall/message');
    },
    name: 'message',
    meta: {
      perms: ['GET /admin/message/list', 'POST /admin/message/create', 'GET /message/delete'],
      path: ['GET /admin/message/list', 'POST /admin/message/create'],
      title: i18n.tc('menus.messageCenter'),
      noCache: true
    }
  }]
}, {
  path: '/goods',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'goodsManage',
  meta: {
    title: i18n.tc('menus.goodsManage'),
    icon: 'product'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/goods/list');
    },
    name: 'goodsList',
    meta: {
      perms: ['GET /admin/goods/list', 'POST /admin/goods/delete'],
      title: i18n.tc('menus.goods'),
      noCache: true
    }
  }, {
    path: 'create',
    component: function component() {
      return import('@/views/goods/create');
    },
    name: 'goodsCreate',
    meta: {
      perms: ['POST /admin/goods/create'],
      title: i18n.tc('menus.goodsOnSale'),
      noCache: true
    }
  }, {
    path: 'edit',
    component: function component() {
      return import('@/views/goods/edit');
    },
    name: 'goodsEdit',
    meta: {
      perms: ['GET /admin/goods/detail', 'POST /admin/goods/update', 'POST /admin/goods/catAndBrand'],
      title: i18n.tc('menus.goodsEdit'),
      noCache: true
    },
    hidden: true
  }, {
    path: 'comment',
    component: function component() {
      return import('@/views/goods/comment');
    },
    name: 'goodsComment',
    meta: {
      perms: ['GET /admin/comment/list', 'POST /admin/comment/delete'],
      title: i18n.tc('menus.goodsComment'),
      noCache: true
    }
  }, {
    path: 'stock',
    component: function component() {
      return import('@/views/goods/stock');
    },
    name: 'goodsStock',
    meta: {
      perms: ['GET /admin/stock'],
      title: i18n.tc('menus.goodsStock'),
      noCache: true
    }
  }]
}, {
  path: '/promotion',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'promotionManage',
  meta: {
    title: i18n.tc('menus.promotionManage'),
    icon: 'promotions'
  },
  children: [{
    path: 'gift-code',
    component: function component() {
      return import('@/views/promotion/giftCode');
    },
    name: 'giftCode',
    meta: {
      perms: ['GET /admin/gift/code/list', 'POST /admin/gift/task/create', 'GET /admin/gift/taskList', 'POST /admin/gift/task/save', 'POST /admin/gift/task/submit'],
      title: i18n.tc('menus.giftCode'),
      noCache: true
    }
  }, {
    path: 'ad',
    component: function component() {
      return import('@/views/promotion/ad');
    },
    name: 'ad',
    meta: {
      perms: ['GET /admin/ad/list', 'POST /admin/ad/create', 'GET /admin/ad/read', 'POST /admin/ad/update', 'POST /admin/ad/delete'],
      title: i18n.tc('menus.adManage'),
      noCache: true
    }
  },
  // {
  //   path: 'coupon',
  //   component: () => import('@/views/promotion/coupon'),
  //   name: 'coupon',
  //   meta: {
  //     perms: ['GET /admin/coupon/list', 'POST /admin/coupon/create', 'POST /admin/coupon/update', 'POST /admin/coupon/delete'],
  //     title: '优惠券管理',
  //     noCache: true
  //   }
  // },
  // {
  //   path: 'couponDetail',
  //   component: () => import('@/views/promotion/couponDetail'),
  //   name: 'couponDetail',
  //   meta: {
  //     perms: ['GET /admin/coupon/list', 'GET /admin/coupon/listuser'],
  //     title: '优惠券详情',
  //     noCache: true
  //   },
  //   hidden: true
  // },
  {
    path: 'topic',
    component: function component() {
      return import('@/views/promotion/topic');
    },
    name: 'topic',
    meta: {
      perms: ['GET /admin/topic/list', 'POST /admin/topic/create', 'GET /admin/topic/read', 'POST /admin/topic/update', 'POST /admin/topic/delete'],
      title: i18n.tc('menus.topicManage'),
      noCache: true
    }
  }, {
    path: 'topic-create',
    component: function component() {
      return import('@/views/promotion/topicCreate');
    },
    name: 'topicCreate',
    meta: {
      perms: ['POST /admin/topic/create'],
      title: i18n.tc('menus.topicCreate'),
      noCache: true
    },
    hidden: true
  }, {
    path: 'topic-edit',
    component: function component() {
      return import('@/views/promotion/topicEdit');
    },
    name: 'topicEdit',
    meta: {
      perms: ['GET /admin/topic/read', 'POST /admin/topic/update'],
      title: i18n.tc('menus.topicEdit'),
      noCache: true
    },
    hidden: true
  }
  // {
  //   path: 'groupon-rule',
  //   component: () => import('@/views/promotion/grouponRule'),
  //   name: 'grouponRule',
  //   meta: {
  //     perms: ['GET /admin/groupon/list', 'POST /admin/groupon/create', 'POST /admin/groupon/update', 'POST /admin/groupon/delete'],
  //     title: '团购规则',
  //     noCache: true
  //   }
  // },
  // {
  //   path: 'groupon-activity',
  //   component: () => import('@/views/promotion/grouponActivity'),
  //   name: 'grouponActivity',
  //   meta: {
  //     perms: ['GET /admin/groupon/listRecord'],
  //     title: '团购活动',
  //     noCache: true
  //   }
  // }
  ]
}, {
  path: '/sales',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'SalesManage',
  meta: {
    title: i18n.tc('menus.salesManage'),
    icon: 'sales'
  },
  children: [{
    path: 'member',
    component: function component() {
      return import('@/views/sales/member');
    },
    name: 'member',
    meta: {
      // perms: ['GET /admin/admin/nodeList'],
      title: i18n.tc('menus.adminMember')
    }
  }, {
    path: 'dealer',
    component: function component() {
      return import('@/views/sales/dealer');
    },
    name: 'dealer',
    meta: {
      perms: ['GET /admin/user/dealerList'],
      title: i18n.tc('menus.dealer')
    }
  }, {
    path: 'inviteCode',
    component: function component() {
      return import('@/views/sales/inviteCode');
    },
    name: 'inviteCode',
    meta: {
      perms: ['GET /admin/activation/task/list'],
      title: i18n.tc('menus.inviteCode')
    }
  }, {
    path: 'order',
    component: function component() {
      return import('@/views/sales/order');
    },
    name: 'salesOrder',
    meta: {
      title: i18n.tc('menus.salesOrder')
    }
  }]
}, {
  path: '/sys',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'sysManage',
  meta: {
    title: i18n.tc('menus.systemManage'),
    icon: 'system'
  },
  children: [{
    path: 'admin',
    component: function component() {
      return import('@/views/sys/admin');
    },
    name: 'admin',
    meta: {
      perms: ['GET /admin/admin/list', 'POST /admin/admin/create', 'POST /admin/admin/update', 'POST /admin/admin/delete'],
      title: i18n.tc('menus.adminManage'),
      noCache: true
    }
  }, {
    path: 'log',
    component: function component() {
      return import('@/views/sys/log');
    },
    name: 'log',
    meta: {
      perms: ['GET /admin/log/list'],
      title: i18n.tc('menus.logs'),
      noCache: true
    }
  }, {
    path: 'role',
    component: function component() {
      return import('@/views/sys/role');
    },
    name: 'role',
    meta: {
      perms: ['GET /admin/role/list', 'POST /admin/role/create', 'POST /admin/role/update', 'POST /admin/role/delete', 'GET /admin/role/permissions', 'POST /admin/role/permissions'],
      title: i18n.tc('menus.roleManage'),
      noCache: true
    }
  }, {
    path: 'os',
    component: function component() {
      return import('@/views/sys/os');
    },
    name: 'os',
    meta: {
      perms: ['GET /admin/storage/list', 'POST /admin/storage/create', 'POST /admin/storage/update', 'POST /admin/storage/delete'],
      title: i18n.tc('menus.objectStorage'),
      noCache: true
    }
  }]
}, {
  path: '/config',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'configManage',
  meta: {
    title: i18n.tc('menus.configManage'),
    icon: 'setting'
  },
  children: [{
    path: 'notification',
    component: function component() {
      return import('@/views/config/notification');
    },
    name: 'notification',
    meta: {
      perms: ['POST /admin/config/updateReceivers'],
      title: i18n.tc('menus.notification'),
      noCache: true
    }
  }, {
    path: 'mall',
    component: function component() {
      return import('@/views/config/mall');
    },
    name: 'configMall',
    meta: {
      perms: ['GET /admin/config/mall', 'POST /admin/config/mall'],
      title: i18n.tc('menus.mallConfig'),
      noCache: true
    }
  }, {
    path: 'express',
    component: function component() {
      return import('@/views/config/express');
    },
    name: 'configExpress',
    meta: {
      perms: ['GET /admin/config/express', 'POST /admin/config/express'],
      title: i18n.tc('menus.expressConfig'),
      noCache: true
    }
  }, {
    path: 'order',
    component: function component() {
      return import('@/views/config/order');
    },
    name: 'configOrder',
    meta: {
      perms: ['GET /admin/config/order', 'POST /admin/config/order'],
      title: i18n.tc('menus.orderConfig'),
      noCache: true
    }
  },
  // {
  //   path: "dealer-level",
  //   component: () => import("@/views/config/dealerLevel"),
  //   name: "dealerLevel",
  //   meta: {
  //     perms: [
  //       "GET /admin/level/list",
  //       "POST /admin/level/update",
  //       "POST /admin/level/save",
  //       "POST /admin/level/delete"
  //     ],
  //     title: i18n.tc("menus.dealerProfit"),
  //     noCache: true
  //   }
  // },
  // {
  //   path: "commission",
  //   component: () => import("@/views/sales/commission"),
  //   name: "commission",
  //   meta: {
  //     perms: ["GET /admin"],
  //     title: i18n.tc("menus.commission"),
  //     noCache: true
  //   }
  // },
  {
    path: 'withdraw',
    component: function component() {
      return import('@/views/config/withdraw');
    },
    name: 'withdraw',
    meta: {
      perms: ['GET /config/withdraw'],
      title: i18n.tc('menus.configWithdraw'),
      noCache: true
    }
  }
  // {
  //   path: 'wx',
  //   component: () => import('@/views/config/wx'),
  //   name: 'configWx',
  //   meta: {
  //     perms: ['GET /admin/config/wx', 'POST /admin/config/wx'],
  //     title: '小程序配置',
  //     noCache: true
  //   }
  // }
  ]
}, {
  path: '/stat',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'statManage',
  meta: {
    title: i18n.tc('menus.statistics'),
    icon: 'chart'
  },
  children: [{
    path: 'user',
    component: function component() {
      return import('@/views/stat/user');
    },
    name: 'statUser',
    meta: {
      perms: ['GET /admin/stat/user'],
      title: i18n.tc('menus.userStatistics'),
      noCache: true
    }
  }, {
    path: 'order',
    component: function component() {
      return import('@/views/stat/order');
    },
    name: 'statOrder',
    meta: {
      perms: ['GET /admin/stat/order'],
      title: i18n.tc('menus.orderStatistics'),
      noCache: true
    }
  }, {
    path: 'goods',
    component: function component() {
      return import('@/views/stat/goods');
    },
    name: 'statGoods',
    meta: {
      perms: ['GET /admin/stat/goods'],
      title: i18n.tc('menus.goodsStatistics'),
      noCache: true
    }
  }, {
    path: 'productFavorites',
    component: function component() {
      return import('@/views/stat/productFavorites');
    },
    name: 'productFavorites',
    meta: {
      perms: ['GET /admin/stat/productFavorites'],
      title: i18n.tc('商品收藏量'),
      noCache: true
    }
  }, {
    path: 'productViews',
    component: function component() {
      return import('@/views/stat/productViews');
    },
    name: 'productViews',
    meta: {
      perms: ['GET /admin/stat/productViews'],
      title: i18n.tc('商品浏览量'),
      noCache: true
    }
  }, {
    path: 'pageView',
    component: function component() {
      return import('@/views/stat/pageView.vue');
    },
    name: 'pageView',
    meta: {
      perms: ['GET /admin/stat/pageView'],
      title: i18n.tc('mallPageViews'),
      noCache: true
    }
  }]
},
// {
//   path: 'external-link',
//   component: Layout,
//   redirect: 'noredirect',
//   alwaysShow: true,
//   name: 'externalLink',
//   meta: {
//     title: '外链',
//     icon: 'link'
//   },
//   children: [
//     {
//       path: 'https://cloud.tencent.com/product/cos',
//       meta: { title: '腾讯云存储', icon: 'link' }
//     },
//     {
//       path: 'https://cloud.tencent.com/product/sms',
//       meta: { title: '腾讯云短信', icon: 'link' }
//     },
//     {
//       path: 'https://pay.weixin.qq.com/index.php/core/home/login',
//       meta: { title: '微信支付', icon: 'link' }
//     },
//     {
//       path: 'https://mpkf.weixin.qq.com/',
//       meta: { title: '小程序客服', icon: 'link' }
//     },
//     {
//       path: 'https://www.alibabacloud.com/zh/product/oss',
//       meta: { title: '阿里云存储', icon: 'link' }
//     },
//     {
//       path: 'https://www.qiniu.com/products/kodo',
//       meta: { title: '七牛云存储', icon: 'link' }
//     },
//     {
//       path: 'http://www.kdniao.com/api-track',
//       meta: { title: '快递鸟', icon: 'link' }
//     }
//   ]
// },
{
  path: '/profile',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  children: [{
    path: 'password',
    component: function component() {
      return import('@/views/profile/password');
    },
    name: 'password',
    meta: {
      title: i18n.tc('menus.changePassword'),
      noCache: true
    }
  }],
  hidden: true
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];