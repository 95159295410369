import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _objectSpread from "/builds/mall/duubee-mall-admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocal from 'element-ui/lib/locale/lang/en';
import elementEsLocal from 'element-ui/lib/locale/lang/es';
import elementZhCNLocal from 'element-ui/lib/locale/lang/zh-CN';
import enLocal from '@/locales/lang/en';
import esLocal from '@/locales/lang/es';
import zhLocal from '@/locales/lang/zh';
Vue.use(VueI18n);
var messages = {
  en: _objectSpread(_objectSpread({}, enLocal), elementEnLocal),
  es: _objectSpread(_objectSpread({}, esLocal), elementEsLocal),
  zh: _objectSpread(_objectSpread({}, zhLocal), elementZhCNLocal)
};
export function getLanguage() {
  var chooseLanguage = Cookies.get('language');
  if (chooseLanguage) return chooseLanguage;
  var language = (navigator.language || navigator.browserLanguage).toLowerCase().substr(0, 2);
  var locales = Object.keys(messages);
  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return process.env.VUE_APP_DEFAULT_LANGUAGE_CODE;
}
var i18n = new VueI18n({
  locale: getLanguage(),
  messages: messages
});
export default i18n;