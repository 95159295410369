import _objectSpread from "/builds/mall/duubee-mall-admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import Screenfull from '@/components/Screenfull';
import SizeSelect from '@/components/SizeSelect';
import Language from '@/components/Language';
export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger,
    Screenfull: Screenfull,
    SizeSelect: SizeSelect,
    Language: Language
  },
  computed: _objectSpread({}, mapGetters(['sidebar', 'name', 'positions', 'avatar', 'device'])),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('toggleSideBar');
    },
    logout: function logout() {
      this.$store.dispatch('LogOut').then(function () {
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    }
  }
};