var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    { attrs: { "wrap-class": "scrollbar-wrapper" } },
    [
      _c(
        "el-menu",
        {
          attrs: {
            "show-timeout": 200,
            "default-active": _vm.$route.path,
            collapse: _vm.isCollapse,
            mode: "vertical",
            "background-color": "#e6e8e6",
            "text-color": "#333",
            "active-text-color": "#679d85",
          },
        },
        [
          this.sidebar.opened
            ? _c(
                "div",
                {
                  staticClass: "el-submenu__title",
                  staticStyle: { background: "#1a1a1a" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { color: "snow", "font-weight": "600" } },
                    [_vm._v("\n        " + _vm._s(_vm.menuTitle) + "\n      ")]
                  ),
                ]
              )
            : _c("img", {
                staticStyle: { width: "100%", height: "80%", padding: "10px" },
                attrs: {
                  src: "https://object.sales.duubee.com/public/duubee.png",
                  alt: "duubee",
                },
              }),
          _vm._v(" "),
          _vm._l(_vm.permission_routers, function (route) {
            return _c("sidebar-item", {
              key: route.path,
              attrs: { item: route, "base-path": route.path },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }