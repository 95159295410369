export default {
  star: '赞',
  name: '名称',
  id: 'Id',
  address: '地址',
  addressId: '地址Id',
  actions: '操作',
  standard: '标准',
  specifications: '规格',
  add: '增加',
  all: '所有',
  objectKey: '对象键',
  objectName: '对象值',
  objectSize: '对象大小',
  objectInfo: '对象信息',
  days: '天',
  minutes: '分钟',
  userOrderPayTimeout: '用户付款超时',
  orderTotal: '订单',
  orderInfo: '订单信息',
  share: '分享',
  configuration: '配置',
  mallName: '商城名称',
  orderConfirmReceiveTimeout: '订单确认收货超时',
  orderCommentTimeout: '订单评价超时',
  orderAutoCancel: '订单自动取消',
  orderAutoConfirmReceive: '订单自动确认收货',
  orderAutoDisableComment: '订单自动取消评价',
  orderAutoDisableReturn: '订单自动取消退货资格',
  orderReturnTimeout: '订单退货超时',
  goodsTotal: '所有商品',
  productTotal: '商品',
  userTotal: '所有用户',
  loginRequired: '请登录后操作',
  noPermissionsAlert: '没有权限',
  requestTimeout: '请求超时',
  results: '结果',
  deleted: '删除',
  number: '数量',
  picture: '图片',
  pictures: '图片',
  content: '内容',
  goodsReviews: '商品评价',
  pictureUrl: '商品链接',
  objectUrl: '对象链接',
  regionName: '地区名称',
  regionCode: '地区编号',
  regionType: '地区类型',
  type: '类型',
  emailNotification: '通知人邮箱地址',
  freeze: '冻结',
  ipAddress: 'IP地址',
  adminUser: 'Admin用户',
  uploadObject: '上传对象',
  uploadErrorHint: '上传失败，请稍后再试',
  browserUnSupportFullScreen: '浏览器不支持全屏',
  screenLayoutSizeChangeSuccess: '布局尺寸变更成功',
  adminUsers: 'Admin用户',
  searchHint: '搜素',
  roleNameHint: '请输入角色名',
  roleName: '角色名',
  description: '描述',
  grant: '授权',
  permissions: '权限',
  roleNameEmptyHint: '角色名不能为空',
  update: '更新',
  coupon: '优惠卷',
  coupons: '优惠卷',
  default: '默认',
  yes: '是',
  no: '否',
  province: '省',
  refresh: '刷新',
  close: '关闭',
  closeOthers: '关闭其他',
  closeAll: '关闭所有',
  layoutSize: '布局尺寸',
  fullScreen: '全屏',
  changePassword: '更改密码',
  city: '市',
  area: '区',
  goodsId: '商品Id',
  goodsIdSelect: '请先选择商品id',
  goodsIdSelectName: '请选择商品',
  goodsSeleter: '商品选择器',
  goodsIdHint: '请输入商品Id',
  newProducts: '新上商品',
  more: '更多',
  brands: '品牌',
  trending: '热销',
  topics: '专题',
  home: '主页',
  unit: '单位',
  gallery: '画廊',
  sharePicture: '分享图片',
  counterPrice: '专柜价',
  isNew: '新品',
  notNew: '非新品',
  categories: '分类',
  cart: '购物车',
  my: '我的信息',
  serviceCenter: '服务中心',
  feedback: '反馈',
  faq: '常见问题',
  cancel: '取消',
  orders: '订单',
  order: '订单',
  orderSn: '订单编号',
  orderStatus: '订单状态',
  orderPrice: '订单价格',
  actualPrice: '实际价格',
  payTime: '付款时间',
  shipSn: '物流编号',
  shipChannel: '物流公司',
  ship: '发货',
  userInfo: '用户信息',
  orderBy: '排序',
  detail: '详情',
  orderDetail: '订单详情',
  orderNumber: '订单编号',
  orderCancelled: '订单已取消',
  favorites: '收藏',
  shipping: '发货',
  shippingAddress: '发货地址',
  selectAll: '选择所有',
  amount: '总计',
  checkout: '下单',
  delete: '删除',
  edit: '编辑',
  complete: '完成',
  addTime: '添加时间',
  favoriteId: '收藏Id',
  feedbackId: '反馈Id',
  feedbackType: '反馈类型',
  feedbackContent: '反馈内容',
  feedbackPicture: '反馈图片',
  feedbackIdHint: '请输入反馈Id',
  adminUsernameHint: '用户名',
  adminUserId: '用户Id',
  adminRole: '权限配置',
  userFeedback: '用户反馈',
  selectHint: '请选择',
  fieldEmptyHint: '值不能为空',
  crete: '创建',
  userFavorites: '用户收藏',
  history: '历史',
  clearHistory: '清除历史',
  openOrders: '打开订单',
  inTransit: '运输中',
  processing: '处理中',
  pending: '待处理',
  pendingPayment: '待付款',
  new: '新品',
  nameInfo: '姓名',
  used: '已使用',
  expired: '过期',
  expireTime: '过期时间',
  contactUs: '联系我们',
  empty: '空',
  select: '选择',
  spec: '规格',
  specs: '规格',
  error: '错误',
  specName: '规格名称',
  spacTpye: '规格分类',
  userIdHint: '请输入用户Id',
  keyword: '关键字',
  keywords: '关键字',
  keywordHint: '请输入关键字',
  url: 'Url',
  urlHint: '请输入Url',
  orderSnHint: '请输入订单序列号',
  orderStatusHint: '请选择状态',
  hot: '热销',
  isHot: '是否热销',
  notHot: '非热销',
  notDefault: '非默认',
  isOnSale: '是否在售',
  onSale: '在售',
  notOnSale: '已下架',
  isDefault: '是否默认',
  normal: '正常',
  setting: '设置',
  goodsDescriptions: '商品描述',
  specSetting: '规格设置',
  level: '等级',
  level1: '一级',
  level2: '二级',
  categoryPicture: '分类图片',
  issue: '问题',
  issueHint: '请输入内容搜索',
  contentHint: '请输入内容',
  answer: '回答',
  parentLevel: '父级',
  searchId: '搜索Id',
  print: '打印',
  footprintId: '足迹Id',
  userSearchHistory: '用户搜索历史',
  searchHistoryKeyword: '搜索历史关键字',
  propName: '参数名',
  propValue: '参数值',
  goodsProps: '商品参数',
  goodsSpec: '商品规格',
  discountedPrice: '优惠金额',
  specValue: '规格值',
  specPicture: '规格图片',
  singleSpec: '单规格',
  multipleSpecs: '多规格',
  productStock: '商品库存',
  products: '商品',
  sku: 'SKU',
  skuSpecs: 'SKU规格',
  skuSeleter: 'sku选择器',
  skuIdSelect: '请选择SKU',
  purchasePrice: '采购价',
  afterSalesCost: '售后成本',
  logisticsCost: '物流成本',
  taxes: '税费',
  provincialAgency: '省代理分成',
  cityAgencyCommission: '市代理分成',
  marketPrice: '市代理价',
  provincialPrice: '省代理价',
  provinciaMarket: '省代理价=店面价格-市代理价-省代理价',
  marketProvincia: '市代理价=店面价格-市代理价',
  storePrice: '店面价',
  retailPrice: '零售价',
  suggestedRetailPrice: '指导价',
  provincialNumber: '省代理起购数量',
  cityAgencyNumber: '市代理起购数量',
  retailNumber: '店铺代理起购数量',
  success: '成功',
  stock: '库存',
  properties: '属性',
  details: '详情',
  language: '语言',
  onlySupportTwoSpecsForNow: '只支持两种规格',
  search: '搜索',
  searchName: '搜索名称',
  views: '查看',
  generateQrCode: '生成二维码',
  price: '价格',
  editGoods: '编辑商品',
  goodsReservePrice: '优惠价格',
  goodsName: '商品名称',
  goodsBrief: '商品简介',
  goodsDetail: '商品详情',
  goodsSn: '商品序列号',
  goodsSnHint: '请输入商品序列号',
  goodsNameHint: '请输入商品名称',
  addGoodsSpec: '添加商品规格',
  reviewContent: '评价内容',
  customers: '买家',
  title: '标题',
  subtitle: '主题',
  titleHint: '请输入标题',
  subtitleHint: '请输入主题',
  adTitleHint: '请输入广告标题',
  view: '查看',
  backToTop: '返回顶部',
  adId: '广告Id',
  adTitle: '广告标题',
  adContent: '广告内容',
  position: '位置',
  activeLink: '活动链接',
  enable: '激活',
  disable: '禁用',
  adPosition: '广告位置',
  adContentHint: '请输入广告内容',
  homePage: '主页',
  pictureUploadHint: '上传jpg/png文件, 大小不超过1024kb',
  orderTime: '下单时间',
  cancelOrder: '取消订单',
  deleteOrder: '删除订单',
  pay: '付款',
  receipt: '确认收货',
  refund: '退款',
  expressCompany: '物流公司',
  trackingNumber: '物流编号',
  orderDeleted: '订单已删除',
  review: '评价',
  logout: '退出账号',
  avatar: '头像',
  male: '男',
  female: '女',
  secrecy: '保密',
  mobile: '手机号码',
  gender: '性别',
  password: '密码',
  refundCancelled: '退款申请已取消',
  refundBeingProcessed: '正在处理退款申请',
  refundCompleted: '退款完成',
  contactNumber: '联系号码',
  updateSuccess: '更新成功',
  confirm: '确认',
  coding: '编码',
  searchCoding: '搜索编码',
  roleType: '用户类型',
  userLevel: '等级',
  silver: '银牌',
  golden: '金牌',
  provincialAgents: '省代理',
  cityAgent: '市代理',
  storefront: '店面',
  diamond: '钻石',
  member: '会员',
  dealer: '经销商',
  oldPassword: '旧密码',
  newPassword: '新密码',
  confirmNewPassword: '确认新密码',
  passwordNotMatch: '密码不匹配',
  passwordEmptyHint: '密码不能为空',
  userIssue: '用户问题',
  brand: '品牌',
  brandIdHint: '请输入品牌Id',
  brandNameHint: '请输入品牌名称',
  brandId: '品牌Id',
  brandName: '品牌名称',
  category: '类别',
  categoryId: '类别Id',
  categoryName: '类别名称',
  categoryIcon: '类别图标',
  birthday: '出生日期',
  phoneNumber: '手机号码',
  addressDetail: '地址详情',
  userId: '用户Id',
  receiver: '收货人',
  receiverHint: '收货人名称',
  searchingHint: '搜索中...',
  export: '导出',
  nickname: '用户名',
  username: '账号',
  usernameHint: '账号',
  receiveUsername: '账号',
  phoneNumberHint: '手机号码',
  status: '状态',
  unregister: '未注册',
  invalid: '不可用',
  active: '激活',
  memberList: '用户列表',
  create: '创建',
  dealerProfit: '经销商利润率',
  profitRate: '利润率',
  deleteAlert: '永久删除，确认继续？',
  ok: 'OK',
  warning: '警告',
  deleteSuccess: '删除成功',
  loginHead: '曼多萨商城管理后台',
  login: '登陆',
  passwordEmpty: '密码为空',
  passwordLengthInvalid: '密码长度不匹配',
  accountEmpty: '账号为空',
  normalAction: '正常操作',
  safeAction: '安全操作',
  orderAction: '订单操作',
  otherAction: '其他操作',
  paymentInfo: '支付信息',
  orderRemark: '订单备注',
  receiverType: '推送用户',
  messageType: '消息类型',
  messageContent: '消息内容',
  messageSubject: '消息标题',
  messageTypeEmptyHint: '请选择消息类型',
  receiverTypeEmptyHint: '请选择接收用户',
  messageContentEmptyHint: '请输入消息内容',
  messageSubjectEmptyHint: '请输入消息标题',
  revoke: '撤回',
  publish: '发布',
  loadingHint: '加载中...',
  objectId: '对象Id',
  limitType: '限制规则',
  purchaseMin: '起购数量',
  purchaseMax: '限购数量',
  showTieredPrice: '显示阶梯价',
  subscribeCheck: '需订阅公众号',
  minimumSpendingRequiredForFreeShipping: '运费满减所需最低消费',
  shippingCostsHint: '运费满减不足所需运费',
  refundAmount: '退款金额',
  account: '账号',
  emailAddress: '邮箱地址',
  notificationEmailHint: '接收通知的邮箱地址',
  defaultSelect: '默认选择',
  onlySupportOneDefaultSelect: '只支持一种默认选择',
  applicationType: '申请类型',
  expiredTime: '过期时间',
  discountRate: '折扣率',
  applicationQuantity: '申请数量',
  activationCode: '邀请码',
  applicationReason: '申请原因',
  applicant: '申请人',
  submit: '提交',
  createApplication: '创建申请',
  submitApplication: '提交申请',
  saveDraft: '保存草稿',
  submitTime: '提交时间',
  approve: '同意',
  reject: '拒绝',
  approvalComments: '审批意见',
  resubmit: '重新提交',
  approveTime: '审批时间',
  reviewer: '审批人',
  applicationForm: '申请表单',
  applicationCodeList: '申请码列表',
  staffPosition: '职位',
  staffSuperior: '上级',
  staffSuperiorId: '上级用户Id',
  codeUseTime: '使用时间',
  salesCommission: '销售提成',
  activeType: '激活类型',
  superiorAgent: '上级代理',
  masterName: '上级代理',
  updateDraft: '更新草稿',
  checker: '审核人',
  checkedTime: '审核时间',
  listView: '列表视图',
  treeView: '树状视图',
  superiorDealerId: '上级经销商Id',
  superiorDealerId2: '上级经销商平台Id',
  dealerGrade: '经销商等级',
  superiorDealer: '上级经销商',
  lastLoginIp: '最近登录IP',
  lastLoginTime: '最近登录时间',
  showId: '平台Id',
  registerTime: '注册时间',
  emailActive: '邮箱激活',
  weChatSubscribe: '微信订阅',
  weChatAutoRegister: '微信注册',
  salesId: '销售Id',
  myWallet: '我的钱包',
  withdraw: '提现',
  dealerContactNumber: '经销商联系号码',
  orderId: '订单Id',
  createTime: '创建时间',
  commissionAmount: '提成金额',
  withdrawAmount: '金额',
  totalCommission: '总收益',
  goodsInfo: '商品信息',
  costInfo: '费用信息',
  goodsPrice: '商品价',
  memberPrice: '会员价',
  courierFees: '快递费用',
  giftCodeDiscountPrice: '优惠码减免',
  couponDiscountPrice: '优惠卷减免',
  offPriceDiscount: '折扣减免',
  integralDiscountPrice: '积分减免',
  orderNum: '排序位置',
  accountType: '账号类型',
  accountId: '账号Id',
  withdrawType: '提现类型',
  withdrawConfirm: '提现确认',
  transferConfirm: '转账确认',
  remark: '备注',
  offlineTransferConfirm: '线下转账确认',
  selectTypeHint: '请选择类型',
  paymentAccountName: '账户名',
  paymentAccount: '账户号码',
  paymentAccountRequiredHint: '请添加支付账户',
  receivingAccount: '收款账户',
  dealWith: '处理',
  tradeAccountName: '账户名',
  tradeAccount: '账户号码',
  WalletBalance: '余额',
  singleTime: '单次',
  everyTime: '每次',
  limitByDays: '按天限制',
  bom: 'BOM',
  rules: '规则',
  purchaseRules: '购买规则',
  addRules: '添加规则',
  uploadPictureOverLimitHint: '上传图片超出限制数量',
  singleMinimumAmount: '单次最低金额',
  dailyLimit: '每日最高额度',
  quantity: '数量',
  count: '实时库存数',
  inventoryQuantity: '库存数量',
  thumbnail: '缩略图',
  adminPassword: '管理员密码',
  pageNotFound: '页面不存在',
  back: '返回',
  backToHome: '返回首页',
  bankNo: '银行卡号',
  contactInformation: '联系方式',
  bankAddress: '开户行地址',
  bankType: '银行类型',
  withdrawalAmount: '提现金额',
  notVerificationCode: '验证码不能为空',
  enterCode: '请根据以上图片输入验证码',
  verificationCode: '验证码',
  refreshImg: '若图片不清楚,可点击图片刷新',
  remittance: '汇款',
  confirmApproval: '确认审批通过?',
  isReject: '是否拒绝？',
  superiorAgentRequired: '上级代理为必填',
  discountRateMess: '打九折输入数字9，九点九折输入数字9.9',
  purchaseMaxMess: '一个优惠码可以购买的数量',
  createQuantityMess: '申请多少个优惠码',
  productViews: '商品浏览量',
  pageViews: '浏览次数',
  pageTraffic: '浏览量',
  productFavorites: '商品收藏量',
  mallPageViews: '商城访问量',
  unitTime: '单位/次',
  month: '月份',
  pageView: '访问量',
  favoritesCount: '收藏次数',
  favoritesVolume: '收藏量',
  times: '次',
  suggestDivideInto: '推荐分成',
  suggestPrice: '推荐价格',
  securityVerification: '请完成安全验证',
  loadingCaptcha: '正在加载验证码...',
  verificationFailed: '验证失败',
  verificationSuccessful: 's验证成功',
  swipeToVerify: '向右滑动完成验证',
  manualEntryCount: '输入库存数量',
  realTimeCount: '实时库存数量',
  withdrawStatusCode: {
    51: '待处理',
    101: '已完成'
  },
  paymentAccountTypeCode: {
    20: '支付宝',
    30: '微信',
    40: 'PayPal'
  },
  tradeTypeCode: {
    10: '线下',
    0: '线上'
  },
  accountTypeCode: {
    1: '销售',
    2: '经销商'
  },
  languagesCode: {
    en: 'English',
    zh: '简体中文',
    es: 'Español'
  },
  tradeStatus: {
    0: '待审批',
    1: '待汇款',
    2: '待确认',
    3: '完成',
    4: '拒绝'
  },
  withdrawStatusBtn: {
    0: '通过',
    1: '拒绝'
  },
  orderStatusCode: {
    101: '未付款',
    102: '已取消',
    103: '付款超时',
    104: '系统取消',
    128: '已上传支付凭证',
    201: '已付款',
    202: '退款',
    203: '确认退款',
    204: '团购超时',
    228: '确认收款',
    301: '已发货',
    401: '确认收货',
    402: '自动确认收货',
    502: '已完成'
  },
  paymentTypesCode: {
    0: '线下支付',
    1: '支付宝',
    2: '微信',
    3: 'PayPal'
  },
  cancelReasonCode: {
    1: '订单信息拍错（规格/尺寸/颜色等）',
    2: '我不想要了',
    3: '地址/电话信息填写错误',
    4: '未按约定时间发货',
    5: '缺货'
  },
  receiverTypeCode: {
    0: '会员',
    1: '经销商',
    99: '所有用户'
  },
  messageTypeCode: {
    0: '系统',
    1: '订单',
    2: '促销',
    3: '公告'
  },
  giftCodeTypes: {
    1: '优购码',
    2: '优惠码'
  },
  taskStatus: {
    0: '草稿',
    1: '审批中',
    '-1': '不通过',
    2: '批准',
    3: '已审核'
  },
  codeStatus: {
    0: '未审批',
    1: '有效',
    2: '已使用',
    3: '不可用'
  },
  positionCode: {
    999: '国家代表',
    1000: '销售总监',
    1001: '销售经理',
    1002: '销售员'
  },
  dealerLevelCode: {
    0: '平台经销商',
    1: '省代理',
    2: '市代理',
    3: '店面'
  },
  userLevelCode: {
    1: '省代理',
    2: '市代理',
    3: '店面'
  },
  errno: {
    '-2': '支付方式未启用',
    '-1': '错误',
    100: '要求最低购买数量',
    101: '超出最高限购数量',
    102: '每个用户限购一次',
    103: '此商品需要订阅才能购买',
    401: '参数不对',
    402: '参数值不对',
    501: '请登录',
    502: '系统内部错误',
    503: '优购商品不支持更改数量',
    504: '更新数据已经失效',
    505: '更新数据失败',
    506: '无操作权限',
    601: '管理员帐号不符合规定',
    602: '管理员密码长度不能小于6',
    603: '管理员已经存在',
    604: '管理员不能删除自己账号',
    605: '用户帐号或密码不正确',
    606: '邮箱地址不符合规定',
    611: '商品名已经存在',
    620: '订单不能确认收货',
    621: '订单退款失败',
    622: '订单商品已回复',
    640: '角色已经存在',
    641: '当前角色的超级权限不能变更',
    642: '当前角色存在管理员，不能删除',
    650: '团购商品不存在',
    651: '团购商品已经存在',
    652: '团购已经下线',
    700: '账号不存在或密码不正确',
    701: '小程序后台验证码服务不支持',
    702: '验证码未超时1分钟，不能发送',
    703: '验证码错误',
    704: '该用户名已存在',
    705: '手机号已注册',
    706: '手机号未注册',
    707: '手机号格式不正确',
    708: 'openid 获取失败',
    709: 'openid已绑定账号',
    710: '该商品已下架',
    711: '库存不足',
    712: '未知的商品',
    720: '订单不存在',
    721: '不是当前用户的订单',
    724: '订单不能支付',
    725: '订单不能操作',
    726: '订单商品已评价',
    727: '当前商品评价时间已经过期',
    4013: '验证码错误,请重新输入',
    1000: '提现失败',
    2000: '余额不足',
    2010: '可用余额不足',
    2050: '提现服务出错',
    5000: '必须满足最低提现金额',
    5001: '今日提现金额已达上限'
  },
  menus: {
    homePage: '主页',
    userManage: '用户管理',
    memberManage: '会员管理',
    address: '地址',
    collect: '收藏',
    footprint: '足迹',
    history: '历史',
    feedback: '反馈',
    mallManage: '商城管理',
    warehouse: '仓库',
    region: '地区',
    brand: '品牌',
    category: '分类',
    order: '订单',
    issue: 'Q&A',
    keyword: '搜索关键字',
    goodsManage: '商品管理',
    goods: '商品',
    goodsOnSale: '在售',
    goodsEdit: '商品编辑',
    goodsComment: '评价',
    goodsStock: '库存',
    promotionManage: '宣传管理',
    giftCode: '优购码',
    adManage: '广告管理',
    comments: '评价',
    topicManage: '专题管理',
    topicCreate: '创建专题',
    topicEdit: '编辑专题',
    systemManage: '系统管理',
    adminManage: '账号管理',
    logs: '日志',
    roleManage: '角色管理',
    objectStorage: '对象存储',
    configManage: '配置管理',
    notification: '订单通知',
    mallConfig: '商城配置',
    expressConfig: '物流配置',
    orderConfig: '订单配置',
    dealerProfit: '经销商利润',
    statistics: '统计报表',
    userStatistics: '用户',
    orderStatistics: '订单',
    goodsStatistics: '商品',
    changePassword: '更改密码',
    messageCenter: '消息中心',
    salesManage: '销售中心',
    adminMember: '成员管理',
    commission: '销售利润',
    inviteCode: '邀请码',
    dealer: '经销商管理',
    salesOrder: '客户订单',
    wallet: '利润分成',
    finance: '金融中心',
    withdraw: '提现记录',
    payment: '支付方式',
    userWallet: '用户钱包',
    configWithdraw: '提现配置'
  }
};