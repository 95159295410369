import Cookies from 'js-cookie';
var app = {
  state: {
    sidebar: {
      opened: !+Cookies.get('sidebarStatus'),
      withoutAnimation: false
    },
    device: 'desktop',
    language: Cookies.get('language') || 'en',
    size: Cookies.get('size') || 'medium'
  },
  mutations: {
    TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1);
      } else {
        Cookies.set('sidebarStatus', 0);
      }
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
    },
    CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
      Cookies.set('sidebarStatus', 1);
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
      state.device = device;
    },
    SET_LANGUAGE: function SET_LANGUAGE(state, language) {
      state.language = language;
      Cookies.set('language', language);
    },
    SET_SIZE: function SET_SIZE(state, size) {
      state.size = size;
      Cookies.set('size', size);
    }
  },
  actions: {
    toggleSideBar: function toggleSideBar(_ref) {
      var commit = _ref.commit;
      commit('TOGGLE_SIDEBAR');
    },
    closeSideBar: function closeSideBar(_ref2, _ref3) {
      var commit = _ref2.commit;
      var withoutAnimation = _ref3.withoutAnimation;
      commit('CLOSE_SIDEBAR', withoutAnimation);
    },
    toggleDevice: function toggleDevice(_ref4, device) {
      var commit = _ref4.commit;
      commit('TOGGLE_DEVICE', device);
    },
    setLanguage: function setLanguage(_ref5, language) {
      var commit = _ref5.commit;
      commit('SET_LANGUAGE', language);
    },
    setSize: function setSize(_ref6, size) {
      var commit = _ref6.commit;
      commit('SET_SIZE', size);
    }
  }
};
export default app;